import {isInShadowRoot} from "./utils";

export const CALENDAR_GAP = 5;

const gaps = {
    '0.5': '0.125em', // 0.111111em
    '1': '0.25em', // 0.277778em 0.222222em
    '1.5': '0.375em', // 0.3333333em
    '2': '0.5em', // 0.555556em 0.4444445em
    '2.5': '0.625em', // 0.666667em
    '3': '0.75em', // 0.77778em
    '3.5': '0.875em', // '0.88889em' 0.833334em
    '4': '1em', // 1.11111em
    '5': '1.25em', // 1.333334em 1.22222em
    '6': '1.5em', // 1.667em
    '7': '1.75em', // 1.77778em
    '8': '2em',
    '9': '2.25em', // 2.22222em
    '10': '2.5em',
    '11': '2.75em', // 2.666667em
    '12': '3em',
    '14': '3.5em',
    '16': '4em', // 3.88889em
    '20': '5em',
    '22': '5.5em', // 5.55556em
    '24': '6em',
    '28': '7em',
    '32': '8em',
    '36': '9em',
    '40': '10em',
    '44': '11em',
    '48': '12em',
    '52': '13em',
    '56': '14em',
    '60': '15em',
    '64': '16em',
    '72': '18em', // 18.1111em
    '76': '19em', // 19em
    '80': '20em',
    '96': '24em',
    '112': '28em',
    '128': '32em',
    '144': '38em',
    '160': '42em'
};


function rgbStringToHex(rgbString) {
    const rgb = rgbString.match(/\d+/g).map(Number);
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    return "#" + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}

function hexToRGBA(hex, opacity) {
    if(hex.includes('rgb') || hex.includes('rgba')) {
        //hex to w rzeczywistości rgb
        hex = rgbStringToHex(hex);
    }
    if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        throw new Error("Podano nieprawidłowy format HEX");
    }
    let r = 0,
        g = 0,
        b = 0;
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
const appendInterFont = () => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
}

const declareBasicStyles = (state) => {
    state['style'] = {
        color:'#101828',
        textColor: '#fff',
        accentColor: "#444CE7",
        zIndex: 99998,
    }
}

export function createStylesheet(state) {
    if (!state.hasOwnProperty('style')) declareBasicStyles(state);
    appendInterFont();
    const isModule = document.querySelector('.calendar-wrapper');
    const key = isModule ? 1 : state.key; //jeśli jesteśmy w trybie be - ustawiamy dla wszystkich takie same style.
    if (!state.style.hasOwnProperty('accentColor')) {
         state.style['accentColor'] = state.style.color || '#444CE7';
    }
    const {
        color = "#101828",
        textColor = "#fff",
        zIndex = 99998,
        font =  '"Inter", sans-serif',
        fontSize = "18px",
        accentColor = state.style.accentColor,
    } = state.style || {};
    const id = `#calendar-stylesheet-${key}`;
    let existingStylesheet;
    if (state.container && isInShadowRoot(state.container)) {
        existingStylesheet = state.container.getRootNode().querySelector(id);
    } else {
        existingStylesheet = document.querySelector(id);
    }
    if(existingStylesheet){
        return existingStylesheet;
    }

    const stylesheet = document.createElement('style')
    stylesheet.id = id;

    stylesheet.innerHTML = `
    .nf-calendar[key="${key}"] {
      z-index: ${zIndex + 1};
    }
    .nf-calendar[key="${key}"].nf-calendar_template,
    .nf-calendar[key="${key}"] .nf-caldr-container {
      position: relative;  
      font-family: ${font} !important;
      font-size: ${fontSize} !important;
      width: ${state.single ? "100%" : "780px"};
      border-radius: 20px;
    }
    .nf-calendar[key="${key}"].nf-calendar_template {
      padding: 0 ${state.single ? "0" : "24px"};
    }
    .nf-calendar[key="${key}"] .nf-caldr-calendar {
      ${state.single ? "" : "transform: translateX(-52.5%);"}
    }
    .nf-calendar[key="${key}"] .nf-caldr-months-active,
    .nf-calendar[key="${key}"] .nf-caldr-years-active {
      background-color: ${color};
      color: ${textColor};
    }
    .nf-calendar[key="${key}"] .nf-caldr-top {
      display: ${state.headless ? `${state.container ? 'flex': 'none'}` : "flex"};
    }
    .nf-calendar[key="${key}"] .nf-caldr-day-start {
      background-color: ${hexToRGBA(accentColor, 0.2)};
      color: ${textColor} !important;
    }
    .nf-calendar[key="${key}"] .nf-caldr-day-end {
      background-color: ${hexToRGBA(accentColor, 0.2)};
      color: ${textColor} !important;
    }
    .nf-calendar[key="${key}"] .nf-caldr-day-start .nf-caldr-btn-content{
      background-color: ${accentColor};
      border-radius: 50%;
      color: ${textColor} !important;
    }
     .nf-calendar[key="${key}"] .nf-caldr-day-end .nf-caldr-btn-content{
      background-color: ${accentColor};
      border-radius: 50%;
      color: ${textColor} !important;
    }
    .nf-calendar[key="${key}"] .nf-caldr-day-middle {
      color: ${accentColor} !important;
      background-color: ${hexToRGBA(accentColor, 0.2)};
    }
    .nf-caldr-day {
        border: 1px solid transparent;
        border-radius: 50%;
        height: 45px;
        width: 45px !important;
    }

    .nf-caldr-day:hover .nf-caldr-btn-content{
        border: 1px solid ${accentColor};
        border-radius: 50%;
    }
    
    #simplify-calendar input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        text-align: end;
        outline: none;
    }
    
    #simplify-calendar input[type=number] {
        -moz-appearance: textfield;
        text-align: end;
        outline: none;
    }
    
    .dbm-disabled {
        background: #ebebeb !important;
        border-color: transparent !important;
    }
    
    .nf-calendar[key="${key}"] .nf-caldr-day-start.nf-caldr-day--not-available,
    .nf-calendar[key="${key}"] .nf-caldr-day-middle.nf-caldr-day--not-available,
    .nf-calendar[key="${key}"] .nf-caldr-day-end.nf-caldr-day--not-available {
        color: #82888a !important;
    }
    
    .nf-calendar .nf-caldr-day.nf-caldr-day--not-available,
    .nf-calendar .nf-caldr-day-start.nf-caldr-day--not-available,
    .nf-calendar .nf-caldr-day-middle.nf-caldr-day--not-available,
    .nf-calendar .nf-caldr-day-end.nf-caldr-day--not-available {
        background-color: #cacccd !important;
        color: #82888a !important;
        opacity: 1;
    }

    .nf-caldr-btn-content {
        width: 45px; 
        height: 45px;
        display: flex;
        min-width: 45px;
        align-items:center; 
        justify-content: center;
        border: 1px solid transparent;
        font-weight: 600 !important;
        font-size: 14px !important;
    }
    
    .nf-calendar .nf-caldr-below {
        display: flex;
        padding-bottom: ${gaps["4"]};
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-below {
        display: none;
    }

    .nf-calendar .nf-caldr-legend {
        display: flex;
        gap: ${gaps["3.5"]};
        margin-right: auto;
    }
    .nf-calendar .nf-caldr-legend__day-type {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .nf-calendar .nf-caldr-legend__day-type .nf-caldr-day {
        border: 2px solid #cacccd !important;
        height: ${gaps["6"]};
        border-radius: 4px;
        pointer-events: none;
        width: 40px !important;
    }
    .nf-calendar .nf-caldr-legend__label {
        margin-left: ${gaps["2"]};
        font-size: 14px !important;
        font-weight: 500;
    }
    .nf-calendar .nf-caldr-below__clear-dates {
        cursor: pointer;
        background: none;
        border-radius: 8px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 8px 12px;
        border-radius: 8px;
        font-size: 14px !important;
        font-weight: 500;
        background: white;
        border: 1px solid ${color};
        color: ${color}
        opacity:0.8;
        transition: opacity .3s ease;
    }
    .nf-caldr-below__clear-dates:hover {
        opacity: 1;
    }
    
    .nf-calendar[key="${key}"] .nf-caldr-day--not-available-end:not(.nf-caldr-day--not-available) {
        border-left: 1px solid #fcd6d6 !important;
    }

    
    .nf-calendar[key="${key}"] .nf-caldr-day--not-available-end.nf-caldr-day-middle {
        background: #afafaf !important;
    } 

    .nf-calendar[key="${key}"] .nf-caldr-day-middle:hover,
    .nf-calendar[key="${key}"] .nf-caldr-day-middle:focus {
       background-color: ${hexToRGBA(accentColor, 0.2)};
    }
    .nf-calendar[key="${key}"] .nf-caldr-meta {
      color: ${color};
    }
    .nf-calendar[key="${key}"] .nf-caldr-meta-check {
      background-color: ${color};
      color: ${textColor} !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px !important;
      font-weight: 500;
      height: 40px;
    }
    .nf-caldr-month-name,
    .nf-caldr-year-name {
        font-weight: bold;
        color: ${color} !important;
        font-size: 20px !important;
    }
    .nf-calendar[key="${key}"] .nf-caldr-meta-check span {
        font-weight: bold !important;
    }
    .nf-calendar[key="${key}"] .nf-caldr-meta-check:hover {
      background-color: ${color};
    }
    .nf-calendar[key="${key}"] .nf-caldr-meta-check:focus {
      background-color: ${color};
    }
     .nf-calendar[key="${key}"] .nf-lang-btn {
        background-color: ${color};
        color: ${textColor} !important;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        flex-grow: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: initial !important;
        margin: 16px 0 0 auto;
        height: 60px;
        padding: 8px 12px;
    }
    .nf-calendar[key="${key}"] .nf-caldr-dropdown button {
      background-color: ${color};
      font-family: ${font};
    }
    .nf-calendar[key="${key}"] .nf-caldr-next-month,
    .nf-calendar[key="${key}"] .nf-caldr-prev-month {
      padding: 0 ${state.single ? "0" : "16px"};
      width: ${state.single ? "100%" : "50%"};
    }
    .nf-caldr-month-btn {
        background-color: ${color};
    }
    .nf-caldr-months .nf-caldr-months-button {
        transition: border .2s ease;
        border: 1px solid transparent;
        margin: 2px;
        border-radius: 5px;
    }
    
    .nf-calendar .nf-caldr-years-button {
        transition: border .2s ease;
        border: 1px solid transparent;
        border-radius: 5px;
        margin: 2px;
    }
    .nf-calendar .nf-caldr-months-button:hover,
    .nf-calendar .nf-caldr-years-button:hover{
        border: 1px solid ${color};
    }
    .nf-caldr-simplify {}
    .nf-caldr-simplify__item {
        border: 2px solid #bfbebe;
        display: flex;
        align-items:center;
        justify-content: center;
        height: 145px;
        border-radius: 8px;
        flex-direction: column;
        font-size: 13px;
    }
    .nf-caldr-simplify__item .day {
        font-size: 13px;
        margin-left: 4px
    }
     .nf-caldr-simplify__item .days {
        font-size: 13px;
        font-weight: 500;
    }
    .change-date {
        border: none;
        background-color: rgb(16, 24, 40);
        color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-family: "Inter", sans-serif;
        border-radius: 8px;
        font-weight: bold;
        cursor: pointer;
        flex-grow: 0;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: initial !important;
        margin: 16px 0 0 auto;
        padding: 8px 12px;
        margin-top: 59px;
        
    }
    .nf-caldr-simplify__item--selected {
        border: 2px solid ${hexToRGBA(accentColor,.9)};
        font-weight: 500;
        background: ${hexToRGBA(accentColor,0.2)};
     }
  `;
    return stylesheet;
}

export function hasGlobalStylesheet(container = document) {
    return container.querySelector('#global-stylesheet');
}

export function createGlobalStylesheet(container = document) {
    if (hasGlobalStylesheet(container)) {
        return existingStylesheet;
    }

    // to chyba musi trafic do osobnego stylesheetu :(
    const stylesheet = document.createElement("style");
    stylesheet.id = 'global-stylesheet';

    stylesheet.innerHTML = `
    .nf-calendar {
        align-items: center;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        width: 100vw;
        top: 0;
    }
    .nf-calendar .nf-caldr-backdrop {
        background-color: rgba(0,0,0,.6);
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        top: 0;
    }
    .nf-calendar.nf-calendar_template,
    .nf-calendar .nf-caldr-container {
        border-radius: 12px;
        background-color: #fff;
        border-radius: 3px;
        box-sizing: border-box;
        color: #000 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        padding: 40px 40px 30px 40px;
        position: relative;
        user-select: none;
        z-index: 1;
    }
    .nf-calendar.nf-calendar_template {
        width: 100%;
        height: 100%;
    }
    .nf-calendar.nf-calendar_template *,
    .nf-calendar .nf-caldr-container * {
        box-sizing: border-box !important;
        color: inherit;
        font-family:  "Inter", sans-serif;
        font-size: inherit;
        font-weight: 300;
    }
    .nf-calendar button {
        max-width: 100% !important;
        width: auto !important;
    }
    .nf-calendar .nf-caldr-calendar {
        -webkit-overflow-scrolling: touch;
        align-items: flex-start;
        display: flex;
        min-height: 380px;
        padding-bottom: 16px;
        position: relative;
        transition: 400ms transform;
        width: 100%;
    }
    .nf-calendar .nf-caldr-months-container,
    .nf-calendar .nf-caldr-years {
        align-content: flex-start;
        align-items: flex-start;
        background-color: #fff;
        display: none;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        justify-content: flex-start;
        -webkit-overflow-scrolling: touch;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 98;
    }
    .nf-calendar .nf-caldr-years {
      overflow-y: auto;
      padding: 40px;  
      z-index: 99;
    }
    .nf-calendar .nf-caldr-months {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-top: 100px;
        position: relative;
        margin: 0 44px;
    }
    .nf-calendar .nf-caldr-month-name,
    .nf-calendar .nf-caldr-year-name {
        font-weight: bold;
    }
    .nf-calendar .nf-caldr-month-name,
    .nf-calendar .nf-caldr-year-name,
    .nf-calendar .nf-caldr-years-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding-bottom: 20px;
        padding-top: 20px;
        text-transform: capitalize;
        width: 25% !important;
    }
    .nf-calendar .nf-caldr-months-button {
        width: 24% !important;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding-bottom: 20px;
        padding-top: 20px;
        text-transform: capitalize;
    }
    .nf-calendar .nf-caldr-months-active,
    .nf-calendar .nf-caldr-years-active {
        border-radius: 5px;
    }
    .nf-calendar .nf-caldr-months .nf-caldr-year-name {
        color: #a2a5a7;
        position: absolute;
        top: 26px;
        left: 50%;
        transform: translateX(-50%);
    }
    .nf-calendar .nf-caldr-top {
        font-size: 20px !important;
        justify-content: center;
        padding-bottom: 16px;
        margin-bottom: 16px;
        margin-top: 6px;
        width: 100%;
    }
    .nf-calendar .nf-caldr-month {
        display: grid;
        grid-template-columns: repeat(7, calc(100% / 7));
    }
    .nf-calendar .nf-caldr-mobile-close {
        -webkit-appearance: none;
        border-radius: 50%;
        border: none;
        color: #d9d9d9 !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        width: 36px !important;
        min-width: 36px;
        height: 36px;
    }
    
    .nf-calendar .nf-caldr-month-btn {
        border-radius: 50%;
        border: none;
        color: #d9d9d9 !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        padding: 10px;
        position: absolute;
        z-index: 99;
    }
    .nf-calendar .nf-caldr-mobile-close:focus,
    .nf-calendar .nf-caldr-mobile-close:hover,
    .nf-calendar .nf-caldr-mobile-close svg,
    .nf-calendar .nf-caldr-month-btn svg {
        fill: currentColor;
        height: 14px;
        pointer-events: none;
        width: 14px;
    }
    .nf-calendar .nf-caldr-prev-month-btn {
        left: 46px;
    }
    .nf-calendar .nf-caldr-next-month-btn {
        right: 46px;
    }
    .nf-calendar .nf-caldr-mobile-close {
        display: none;
        top: 16px;
        right: 16px;
        min-height: 36px;
    }
    .nf-caldr-close {
        display:none;
    }
    .nf-caldr-close-block {
        display:none;
        height: 36px;
        width: 40px;

        padding: 0 16px;
        position: absolute;
        top: 10px;
        cursor: pointer;
    }
     .nf-caldr-mobile-close::before {
            font-family: 'Material Icons';
            content: '\\e5cd';
            font-size: 20px;
            color: black;
        }
    .nf-calendar .nf-caldr-weekdays {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .nf-calendar .nf-caldr-weekdays li {
        align-items: center;
        display: flex;
        flex-grow: 1;
        font-family: inherit;
        font-size: inherit;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        font-size: 12px;
    
        color: #667085;
    }
    .nf-calendar .nf-caldr-day {
        align-items: center;
        background-color: transparent;
        border: unset !important;
        border-radius: 0;
        cursor: pointer;
        display: flex;
        height: 45px;
        width: 45px;
        min-width: 45px;
        max-width: 45px;
        justify-content: center;
    
        line-height: initial !important;
        outline: none;
        padding: 0;
        transition: none;
    }
    .nf-calendar .nf-caldr-day:focus,
    .nf-calendar .nf-caldr-day:hover {
        background-color: #fff;
        color: inherit;
    }
    .nf-calendar .nf-caldr-day[disabled] {
        cursor: default;
        opacity: .5;
    }
    .nf-calendar .nf-caldr-day-start {
        border-bottom-left-radius: 999px !important;
        border-bottom-right-radius: 0;
        border-top-left-radius: 999px !important;
        border-top-right-radius: 0;
        margin-left: auto;
        padding-right: calc(50% - 22px);
        position: relative;
    }
    .nf-calendar .nf-caldr-day-end {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 999px !important;
        border-top-left-radius: 0;
        border-top-right-radius: 999px !important;
        margin-right: auto;
        padding-left: calc(50% - 25px);
        position: relative;
        width: calc(50% + 20px) !important;
    }
    .nf-calendar .nf-caldr-day-start.nf-caldr-day-end {
        max-width: 40px !important;
    }
    .nf-calendar .nf-caldr-day-start::after,
    .nf-calendar .nf-caldr-day-end::after {
        border-radius: 50%;
        box-shadow: 0 4px 12px rgba(0,0,0,0.2);
        box-sizing: border-box;
        content: " ";
        position: absolute;
    }
    .nf-calendar .nf-caldr-meta {
        align-items: center;
        border-top: 1px #eaeaea solid;
        display: flex;
        width: 100%;
    }
    .nf-calendar.nf-calendar_template .nf-caldr-next-month {
        padding-left: 0;
        padding-right: 0;
    }
    .nf-calendar .nf-caldr-meta-range,
    .nf-calendar .nf-caldr-meta-count {
        filter: brightness(0.7);
        margin-top: 20px;
    }
    .nf-calendar .nf-caldr-meta-count {
        margin-left: 20px;
        font-weight: bold;
    }
    .nf-calendar .nf-caldr-meta-check {
        border: none;
        border-radius: 8px;
        cursor: pointer;
        flex-grow: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: initial !important;
        margin: 16px 0 0 auto;
        padding: 8px 12px;
    } 
    .nf-calendar .nf-caldr-meta-check:hover {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .nf-calendar .nf-caldr-meta-check:hover .nf-caldr-dropdown {
        opacity: 1;
        visibility: visible;
    }
    .nf-calendar .nf-caldr-dropdown {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: inherit;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        transition: opacity 400ms;
        width: 100% !important;
        visibility: hidden;
    }
    .nf-calendar .nf-caldr-dropdown button {
        border: none;
        color: inherit;
        display: flex;
        justify-content: center;
        padding: 12px 5px;
        font-size: 15px;
        line-height: 1.5;
        text-transform: capitalize;
        width: 100% !important;
    }
    .nf-calendar .nf-caldr-dropdown button:hover {
        filter: brightness(0.9);
    }
    .nf-calendar .nf-caldr-next-month,
    .nf-calendar .nf-caldr-prev-month {
        position: absolute;
    }
    
    .nf-caldr-mobile.nf-calendar .nf-caldr-container {
        align-items: center;
        height: 100%;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-calendar {
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        overflow-y: scroll;
        padding-bottom: 100px;
        transform: none !important;
        width: 100%;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-top {
        display: flex;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-weekdays li {
        width: calc(100% / 7);
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-month {
        grid-template-columns: repeat(7, calc(100% / 7));
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-months-container {
      overflow-y: scroll;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-day-start.nf-caldr-day-end {
        width: 40px !important;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-prev-month,
    .nf-caldr-mobile.nf-calendar .nf-caldr-next-month {
        margin-left: auto;
        margin-right: auto;
        position: static;
        width: 100% !important;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-month-btn {
        display: none !important;
        transform: rotate(90deg);
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-mobile-close {
        display: flex;
        cursor: pointer;
    }
     .nf-caldr-mobile.nf-calendar .nf-caldr-close {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-close-block {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-prev-month {
        min-height: 326px;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-next-month {
        margin-top: 16px;
    }
    /* .nf-caldr-mobile.nf-calendar .nf-caldr-next-month .nf-caldr-weekdays {
    display: none;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-next-month:first-child .nf-caldr-weekdays {
    display: flex;
    } */
    .nf-caldr-mobile.nf-calendar .nf-caldr-meta {
        background-color: #fff;
        bottom: 0;
        padding-bottom: 16px;
        padding-right: 16px;
        position: fixed;
        right: 0;
    }
    .nf-caldr-mobile.nf-calendar .nf-caldr-meta-range,
    .nf-caldr-mobile.nf-calendar .nf-caldr-meta-count {
        display: none;
    }
    /* daty na firefox sa troche za wysoko, ale na mobile start i end sa f */
    @-moz-document url-prefix() {
        .nf-caldr-mobile.nf-calendar .nf-caldr-day-start::after,
        .nf-caldr-mobile.nf-calendar .nf-caldr-day-end::after {
            margin-top: -4px;
        }
        .nf-caldr-mobile.nf-calendar .nf-caldr-meta-check {
            /* padding: 17px 20px 12px 20px; */
            height: 48px;
        }
    }
  `;
    return stylesheet;
}
