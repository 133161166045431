// @ts-check

import {getDaysInMonth} from './dates';
import {DATE_FORMAT} from './config';
import './types';

/**
 * Zwraca template html do footera kalendarza z zasiegiem wybranych dat
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} start
 * @param {import('dayjs').Dayjs} end
 * @returns {string}
 */
export function createDateRangeEl(state, start, end) {
    if (!end || !start) return '';
    if (!isNaN(end.valueOf()) || !isNaN(start.valueOf())) {
        return `${(state.locale[state.currentLanguage] || state.locale['en']).days[start.day() === 0 ? 6 : start.day() - 1]} ${start.date()} ${(state.locale[state.currentLanguage] || state.locale['en']).months[start.month()].slice(0, 3)} - ${(state.locale[state.currentLanguage] || state.locale['en']).days[end.day() === 0 ? 6 : end.day() - 1]} ${end.date()} ${(state.locale[state.currentLanguage] || state.locale['en']).months[end.month()].slice(0, 3)}`
    }
    return state.template.querySelector('.nf-caldr-meta-range')?.textContent || ''
}

/**
 * Zwraca sformatowany tekst z ilosca nocy wybranych w kalendarzu
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} start
 * @param {import('dayjs').Dayjs} end
 * @returns {string}
 */
export function createNightsCountEl(state, start, end) {

    let nightsCount = '0';

    if (start && end) {
        nightsCount = end.diff(start, 'day');
    }

    let noun = 'nocy'
    switch (state.currentLanguage) {
        case 'en':
            noun = nightsCount === 1 ? 'night' : 'nights';
            break;
        case 'pl':
            if (nightsCount === 1) {
                noun = 'noc';
            } else if ((nightsCount < 10 || nightsCount > 20)
                && ['2', '3', '4'].includes((nightsCount + '')
                    .slice(-1))) {
                noun = 'noce';
            } else {
                noun = 'nocy';
            }
            break;
        case 'de':
            noun = nightsCount === 1 ? 'Nacht' : 'Nächte';
            break;
        case 'cs':
            noun = nightsCount === 1 ? 'Noc' : 'Nocí';
            break;
        case 'ru':
            noun = nightsCount === 1 ? 'ночь' : 'ночи';
            break;
        case 'it': // włoski
            noun = nightsCount === 1 ? 'notte' : 'notti';
            break;
        case 'es': // hiszpański
            noun = nightsCount === 1 ? 'noche' : 'noches';
            break;
        case 'fr': // francuski
            noun = nightsCount === 1 ? 'nuit' : 'nuits';
            break;
    }


    return `(${nightsCount} ${noun})`
}

/**
 * Zwraca template html pojedynczego dnia
 * @param {CalState} state
 * @param {number} index
 * @param {import('dayjs').Dayjs} date
 * @returns {string}
 */

function createDayTemplate(state, index, date) {
    // wylacz przycisk jezeli dzien jest przed dzisiejszym
    let isButtonDisabled = false
    if (!date.isBetween(state.minDate, state.maxDate, 'day', '()')) {
        isButtonDisabled = true
    }
    return `
      <button
          ${isButtonDisabled ? "disabled" : ""}
             data-date="${date.format(DATE_FORMAT)}"
            class="nf-caldr-day"
            style="${index === 0
        ? `grid-column: ${date.day() === 0 ? 7 : date.day()};` : ''} ${date.day() === 6
    || date.day() === 0 ? 'color: #cc0a0a' : ''}"
             
    ${
        date.day() === 6 || date.day() === 0
            ? "color: #cc0a0a"
            : ""
    };"
          >
                <span class="nf-caldr-btn-content" data-date="${date.format(DATE_FORMAT)}">
                  ${index + 1}
                  </span>
                </button>`;
}

/**
 * Zwraca template pojedynczego miesiaca w kalendarzu
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} date
 * @returns {string}
 */
export function createCalendarTemplate(state, date) {
    return `
<div class="nf-caldr-top">
<button class="nf-caldr-month-name" style="width:auto !important;padding:0;">${(state.locale[state.currentLanguage] || state.locale['en']).months[date.month()] || (state.locale[state.currentLanguage] || state.locale['en']).months[0]}</button>
<button class="nf-caldr-year-name" style="width:auto !important;margin-left:6px;padding:0;">${date.year()}</button>
</div>
<ul class="nf-caldr-weekdays">
${(state.locale[state.currentLanguage] || state.locale['en']).days.map((day, index) => `<li ${index === 5 || index === 6 ? 'style="color: #cc0a0a !important;"' : ''}>${day}</li>`).join('')}
</ul>
<div class="nf-caldr-month">
${new Array(getDaysInMonth(date))
        .fill(0)
        .map((day, idx) => createDayTemplate(state, idx, date.date(idx + 1)))
        .join('')}
</div>`
}

/**
 * Zwraca template wyboru lat
 * @param {CalState} state
 * @returns {string}
 */
function createYearsTemplate(state) {
    const yearsAmount = state.maxDate.year() - state.minDate.year() + 1
    return `
<div class="nf-caldr-years" style="display:none;">
${new Array(yearsAmount)
        .fill(0)
        .map((year, idx) => {
            return `<button
        class="nf-caldr-years-button ${state.minDate.year() + idx === (state.anchorDate && state.anchorDate.year()) ? 'nf-caldr-years-active' : ''}"
    >
        ${state.minDate.year() + idx}
    </button>`
        })
        .join('')
    }
</div>
`
}

/**
 * Zwraca template wyboru miesiecy
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} date
 * @returns {string}
 */
export function createMonthTemplate(state, date) {
    return `
<div class="nf-caldr-months" data-year="${date.year()}">
${state.headless ? '' : `<button class="nf-caldr-year-name">${date.year()}</button>`}
${(state.locale[state.currentLanguage] || state.locale['en']).months.map((month, idx) => {
        return `
    <button class="nf-caldr-months-button" data-month="${idx}">
    ${month.slice(0, 3)}
    </button>
    `
    }).join('')}
</div>
`
}

/**
 * Zwraca glowny element kalendarza i przygotowuje go do headless
 * @param {CalState} state
 * @returns {HTMLDivElement}
 */
export function createHeadlessTemplate(state) {
    const template = document.createElement('div')
    template.classList.add('nf-calendar_template')
    template.classList.add('nf-caldr-container')
    template.classList.add('nf-calendar')
    state.key && template.setAttribute('key', state.key.toString())

    template.innerHTML = `
    ${state.container ? `
     <button class="nf-caldr-month-btn nf-caldr-prev-month-btn">
        <svg id="FontAwesomeicon-chevron-left" viewBox="0 0 21 28"><title>chevron-left</title><path d="M18.297 4.703l-8.297 8.297 8.297 8.297c0.391 0.391 0.391 1.016 0 1.406l-2.594 2.594c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.594c-0.391-0.391-0.391-1.016 0-1.406l11.594-11.594c0.391-0.391 1.016-0.391 1.406 0l2.594 2.594c0.391 0.391 0.391 1.016 0 1.406z"></path></svg>
    </button>
    `: ''}
       ${createYearsTemplate(state)}
        ${state.container ? `
         <button class="nf-caldr-month-btn nf-caldr-next-month-btn">
        <svg style="transform: rotate(180deg);" id="FontAwesomeicon-chevron-left" viewBox="0 0 21 28"><title>chevron-left</title><path d="M18.297 4.703l-8.297 8.297 8.297 8.297c0.391 0.391 0.391 1.016 0 1.406l-2.594 2.594c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.594c-0.391-0.391-0.391-1.016 0-1.406l11.594-11.594c0.391-0.391 1.016-0.391 1.406 0l2.594 2.594c0.391 0.391 0.391 1.016 0 1.406z"></path></svg>
    </button>
        `: ''}
     
    <div class="nf-caldr-months-container"></div>
    <div class="nf-caldr-calendar"></div>
`

    return template
}

/**
 * Zwraca element calego kalendarza, razem z backdropem
 * @param {CalState} state
 * @returns {HTMLDivElement}
 */
export function createTemplate(state) {
    let onLoadLanguage = window.localStorage.getItem('NfBookingEngine.languageCode')
        || window.sessionStorage.getItem('NfBookingEngine.languageCode');

    if (onLoadLanguage) {
        state.currentLanguage = onLoadLanguage;
    }

    //story: musiałem się na to zdecydować bo nie ładowało svg dla iphona.
    const link = document.createElement('link');
    link.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
    link.rel = "stylesheet";

    document.head.appendChild(link);


    const template = document.createElement('div')
    template.classList.add('nf-calendar')
    template.style.display = 'none'
    state.key && template.setAttribute('key', state.key.toString())

    // check if there are more than one ids
    let isDropdown = false
    let buttonLocale = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].button) || state.locale.en.button;
    let buttonClearLocale = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].clearButton) || state.locale.en.clearButton;
    let freeLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].freeLabel) || state.locale.en.freeLabel;
    let occupiedLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].occupiedLabel) || state.locale.en.occupiedLabel;

    if (state.locale[state.currentLanguage] && Array.isArray(buttonLocale)) {
        isDropdown = true

        // merge custom dropdown names and their locals
        buttonLocale.forEach((item, idx) => {
            state.locale[state.currentLanguage][`id${idx}`] = item
        })
    }

    if (Array.isArray(buttonLocale)) {
        buttonLocale = buttonLocale.slice(1)
    }

    let buttonName = Array.isArray(buttonLocale) ? buttonLocale[0] : buttonLocale;

    template.innerHTML = `
<div class="nf-caldr-backdrop"></div>
<div class="nf-caldr-container">
    <button class="nf-caldr-month-btn nf-caldr-prev-month-btn">
        <svg id="FontAwesomeicon-chevron-left" viewBox="0 0 21 28"><title>chevron-left</title><path d="M18.297 4.703l-8.297 8.297 8.297 8.297c0.391 0.391 0.391 1.016 0 1.406l-2.594 2.594c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.594c-0.391-0.391-0.391-1.016 0-1.406l11.594-11.594c0.391-0.391 1.016-0.391 1.406 0l2.594 2.594c0.391 0.391 0.391 1.016 0 1.406z"></path></svg>
    </button>
    <button class="nf-caldr-month-btn nf-caldr-next-month-btn">
        <svg style="transform: rotate(180deg);" id="FontAwesomeicon-chevron-left" viewBox="0 0 21 28"><title>chevron-left</title><path d="M18.297 4.703l-8.297 8.297 8.297 8.297c0.391 0.391 0.391 1.016 0 1.406l-2.594 2.594c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.594c-0.391-0.391-0.391-1.016 0-1.406l11.594-11.594c0.391-0.391 1.016-0.391 1.406 0l2.594 2.594c0.391 0.391 0.391 1.016 0 1.406z"></path></svg>
    </button>
    <div class="nf-caldr-close">
        <div class="nf-caldr-close-block">
            <button class="nf-caldr-mobile-close"></button>
        </div>
    </div>
    
${createYearsTemplate(state)}
<div class="nf-caldr-months-container"></div>
<div class="nf-caldr-calendar"></div>
<div class="nf-caldr-below">
    ${state.nfhotel && state.nfhotel.instanceId && state.nfhotel.standardId ? `<div class="nf-caldr-legend">
        <div class="nf-caldr-legend__day-type">
            <div class="nf-caldr-day"></div>
            <span class="nf-caldr-legend__label">${freeLabel}</span>
        </div>
        <div class="nf-caldr-legend__day-type">
            <div class="nf-caldr-day nf-caldr-day--not-available"></div>
            <span class="nf-caldr-legend__label">${occupiedLabel}</span>
        </div>
    </div>` : ''}
    <button type="button" class="nf-caldr-below__clear-dates">${buttonClearLocale}</button>
</div>
${state.hideMeta ? '' : `<div class="nf-caldr-meta">
    ${state.single && state.onSelect ? '' : `
    <span class="nf-caldr-meta-range">śr 28 sty - so 3 lut</span>
    <span class="nf-caldr-meta-count">(7 nocy)</span>
    `}
    ${state.onSelect ? `<${isDropdown ? 'div tabindex="0"' : 'button'} class="nf-caldr-meta-check" style="display: flex !important; position: relative; ${isDropdown ? '' : 'border-top-right-radius: 5px !important; border-top-left-radius: 5px !important;'}">
    ${isDropdown ? `<div class="nf-caldr-dropdown" style="top: -${buttonLocale && buttonLocale.length * 46}px">
        ${buttonLocale && (Array.isArray(buttonLocale) ? buttonLocale : [buttonLocale]).map((id, idx) => `<button class="nf-caldr-meta-check-${idx}">${buttonLocale[idx]}</button>`).join('')}
    </div>` : ''}
    <span>${buttonName}</span>
    </${isDropdown ? 'div' : 'button'}>` : ''}
</div>`}
</div>`;

    // template.appendChild(createDayTooltip());

    return template;
}

/**
 * Tworzy tooltip pod dni
 */
function createDayTooltip(state) {
    const tooltip = document.createElement('div');
    tooltip.id = 'nf-caldr-day-tooltip';
    tooltip.setAttribute('role', 'tooltip');
    tooltip.innerHTML = `
        <div id="nf-caldr-day-tooltip__arrow" data-popper-arrow></div>
        Wybrany termin jest niedostępny
    `;
    return tooltip;
}
