// @ts-check

import { getSelectedDates } from './dates';
import { DATE_FORMAT } from './config';
import './types';
import dayjs from 'dayjs';

/**
 * Wysyla powiadomienie o wyboru dat przez `onDateSelect` i event `nfCalendarDate`
 * @param {CalState} state 
 */
export function sendOnDateSelect(state) {
    // callback z zaznaczonymi dniami
    if (state.onDateSelect) {
        state.onDateSelect(getSelectedDates(state)?.map(date => date.toDate()) || [])
    }

    // event z zaznaczonymi dniami
    state.template.dispatchEvent(new CustomEvent('nfCalendarDate', {
        detail: getSelectedDates(state)
    }))
}

/**
 * Zapisuje wybrane daty do sessionStorage jezeli cache jest wlaczony
 * @param {CalState} state 
 */
export function setSessionStorageDates(state) {
    const { disableCache, key, startDate, endDate } = state;
    if (state.parent) {
        const firstMonth = dayjs(state.startDate).month();
        const lastMonth = dayjs(state.endDate).month();
        if (firstMonth && lastMonth) {
             window.sessionStorage.setItem('months', JSON.stringify({
                from: firstMonth,
                to : lastMonth
             }));
        }
    }    
    !disableCache && window.sessionStorage.setItem(key + '-' + 'nf-date-from', startDate.format(DATE_FORMAT));
    if (endDate) {
        !disableCache && window.sessionStorage.setItem(key + '-' + 'nf-date-to', endDate.format(DATE_FORMAT));
    }
}

/**
 * Merguje obiekty tylko dwa poziomy deep
 * @param {Object} target 
 * @param {Object} source 
 * @returns {Object}
 */
export function mergeLocale(target, source) {
    const newLocale = Object.assign({}, target)
    Object.keys(source).forEach(key => {
        newLocale[key] = { ...newLocale[key], ...source[key] }
    })
    return newLocale
}

/**
 * Czy aktualnie kalendarz jest w trybie mobilnym
 * @param {CalState} state 
 * @returns {boolean}
 */
export function isMobile(state) {
    const { template } = state

    return template.classList.contains('nf-caldr-mobile')
}

/**
 * Ustawia tryb mobilny
 * @param {CalState} state 
 * @param {boolean} mobile 
 * @returns {boolean}
 */
export function setMobile(state, mobile) {
    const { template } = state
    let onlySingleCalendar = false;

    if (state.hasOwnProperty('clearMode') && state.hasOwnProperty('single')) {
        onlySingleCalendar = state.clearMode && state.single;
    }
    if (onlySingleCalendar) {
        return false;
    }

    if (mobile && !isMobile(state)) {
        template.classList.add('nf-caldr-mobile')
        return true
    }
    if (!mobile && isMobile(state)) {
        template.classList.remove('nf-caldr-mobile')
        return true
    }
    return false
}

export function isInShadowRoot(element) {
    return element.getRootNode() instanceof ShadowRoot
}
