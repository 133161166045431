const _protocol = 'https';
const _host = 'nfhotel.pl';

/**
 * @type {AvailabilityRequest[]}
 */
const requests = [];

/**
 * @param {number|string} widgetId
 * @param {URLSearchParams} params
 * @return {?AvailabilityRequest}
 */
function removePreviousRequest(widgetId,
                               params,
) {
    const requestIndex = requests.findIndex((request) => {
        return +request.widgetId === widgetId && params.toString() === request.params.toString();
    });
    if (requestIndex === -1) {
        return null;
    }
    const [request] = requests.splice(requestIndex, 1);
    return request;
}

/**
 * @param {number|string} widgetId
 * @param {URLSearchParams} params
 */
function abortPreviousRequest(widgetId,
                              params,
) {
    const request = removePreviousRequest(widgetId, params);
    if (request) {
        request.abortController.abort();
    }
}

/**
 * @param {number|string} widgetId
 * @param {URLSearchParams} params
 * @return {AvailabilityRequest}
 */
function addNewRequest(widgetId,
                       params,
) {
    const request = {
        widgetId,
        params,
        abortController: new AbortController()
    };
    requests.push(request);
    return request;
}

/**
 * @param {number|string} widgetId
 * @param {URLSearchParams} params
 * @param {AvailabilityOptions} options
 * @return {Promise<any>}
 */
async function fetchAvailability(widgetId,
                                 params,
                                 options = {},
) {
    abortPreviousRequest(widgetId, params);
    const {
        abortController,
    } = addNewRequest(widgetId, params);
    try {
        let response = await fetch(
            `${options.protocol ?? _protocol}://api.${options.host ?? _host}/api2/${widgetId}/standard_availability`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params,
                signal: abortController.signal,
            },
        );
        const json = await response.json();
        return json['available_days'] ?? [];
    } catch (err) {
        if (err.name === 'AbortError') {
            throw err;
        }
        return [];
    } finally {
        removePreviousRequest(widgetId, params);
    }
}

export {
    fetchAvailability,
}

/**
 * @typedef AvailabilityRequest
 * @property {number|string} widgetId
 * @property {URLSearchParams} params
 * @property {AbortController} abortController
 */

/**
 * @typedef AvailabilityOptions
 * @property {string} [host]
 * @property {string} [protocol]
 */
