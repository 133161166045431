// @ts-check

import dayjs from 'dayjs';
import { getCurrentDate } from './dates';
import { paintDays } from './drawing';
import { DATE_FORMAT, NFHOTEL_DATE_FORMAT } from './config';
import './types';
import { fetchAvailability } from "./availability";

const availabilityCache = {};

export const availablitityHelperWhenRefreshView = (state) => {
    setTimeout(() => {
        const startDate = getCurrentDate(state);
        let cache = availabilityCache[`${startDate.toISOString()}[${state.nfhotel?.instanceId}-${state.nfhotel?.standardId}]`]
        setAvailabilityAttributes(state, cache || []);
    }, 200);
}

/**
 * Ustawia dostepnosc pokoju na kalendarzu
 * @param {CalState} state
 * @return {boolean}
 */
export function setAvailability(state) {
    if (!state.nfhotel || !state.nfhotel.standardId || !state.nfhotel.instanceId) {
        return false;
    }

    const startDate = getCurrentDate(state);
    const endDate = getCurrentDate(state, 1).endOf('month');

    if (availabilityCache[`${startDate.toISOString()}[${state.nfhotel.instanceId}-${state.nfhotel.standardId}]`]) {
        state.template.querySelector(`.nf-caldr-day[data-date="${startDate.format(DATE_FORMAT)}"]`)?.closest('.nf-caldr-month')?.setAttribute('data-availability-loaded', 'true');
        state.template.querySelector(`.nf-caldr-day[data-date="${endDate.format(DATE_FORMAT)}"]`)?.closest('.nf-caldr-month')?.setAttribute('data-availability-loaded', 'true');
        setAvailabilityAttributes(state, availabilityCache[`${startDate.toISOString()}[${state.nfhotel.instanceId}-${state.nfhotel.standardId}]`]);
        return true;
    }
    const params = new URLSearchParams({
        room_standard_id: `${state.nfhotel.standardId}`,
        from: startDate.format(NFHOTEL_DATE_FORMAT),
        to: endDate.format(NFHOTEL_DATE_FORMAT),
        with_restrictions: 'true'
    });
    fetchAvailability(
        state.nfhotel.instanceId,
        params,
        {
            host: state.apiHost,
            protocol: state.apiProtocol
        }
    )
        .then(availability => {
            availabilityCache[`${startDate.toISOString()}[${state.nfhotel?.instanceId}-${state.nfhotel?.standardId}]`] = availability;
            state.template.querySelector(`.nf-caldr-day[data-date="${startDate.format(DATE_FORMAT)}"]`)?.closest('.nf-caldr-month')?.setAttribute('data-availability-loaded', 'true');
            state.template.querySelector(`.nf-caldr-day[data-date="${endDate.format(DATE_FORMAT)}"]`)?.closest('.nf-caldr-month')?.setAttribute('data-availability-loaded', 'true');
            console.log(availability);
            setAvailabilityAttributes(state, availability)
        })
        .catch(err => {
            if (err.name !== 'AbortError') {
                console.error(err);
            }
            return [];
        });
    return true;
}

function removeAllAvailabilityAttributes(state) {
    state.template.querySelectorAll('.nf-caldr-day[data-availability]')
        .forEach(day => {
            day.removeAttribute('data-availability')
        });
    return true;
}

/**
 * @param {CalState} state
 * @return {boolean}
 */
export function removeAvailability(state) {
    state.template.querySelectorAll('.nf-caldr-month[data-availability-loaded]')
        .forEach(day => {
        day.removeAttribute('data-availability-loaded')
    });
    removeAllAvailabilityAttributes(state);
    paintDays(state);
    return true;
}

/**
 * @param {string[]} availability
 * @return {(day: HTMLElement) => void}
 * TODO [24-01-25'] komentuję do testów
 * TODO [26-02-25'] odkomentowuję do testów xD.
 */
const setAvailabilityForDay = (availability) => (day) => {
    const date = dayjs(day.getAttribute('data-date')).subtract(1, 'day').format(DATE_FORMAT);
    const previousDateElement = day.closest('.nf-caldr-calendar')?.querySelector(`.nf-caldr-day[data-date="${date}"]`);
    if (availability.includes(day.getAttribute('data-date'))) {
        day.setAttribute('data-availability', 'true');
        day.classList.remove('nf-caldr-day--not-available');
    } else if (previousDateElement.getAttribute('data-availability') === 'true' && !previousDateElement.classList.contains('nf-caldr-day--availability-end')) {
        day.setAttribute('data-availability', 'true');
        day.classList.add('nf-caldr-day--availability-end');
        day.classList.remove('nf-caldr-day--not-available');
    } else {
        day.removeAttribute('data-availability');
        day.classList.add('nf-caldr-day--not-available');
    }
}

// TODO [27-02-25'] zakomentowuję wychodzi na to że ta funkcja na górze chyba działa lepiej. jeśli  bd ok to usunąć to.
// const setAvailabilityForDay = (availability) => (day) => {
//     const date = dayjs(day.getAttribute('data-date')).subtract(1, 'day').format(DATE_FORMAT);
//     const previousDateElement = day.closest('.nf-caldr-calendar')?.querySelector(`.nf-caldr-day[data-date="${date}"]`);
//     if (availability.includes(day.getAttribute('data-date'))) {
//         day.setAttribute('data-availability', 'true');
//     } else if (previousDateElement.getAttribute('data-availability') === 'true' && !previousDateElement.classList.contains('nf-caldr-day--availability-end')) {
//         day.setAttribute('data-availability', 'true');
//         day.classList.add('nf-caldr-day--availability-end');
//         //day.setAttribute('disabled', 'true');
//     } else {
//         day.removeAttribute('data-availability');
//         day.classList.add('nf-caldr-day--not-available');
//     }
// }

/**
 * @param {CalState} state
 * @param {string[]} availability
 * @return {boolean}
 */
function setAvailabilityAttributes(state, availability) {
    const startMonth = state.template.querySelector(`.nf-caldr-day[data-date="${getCurrentDate(state).format(DATE_FORMAT)}"]`)?.closest('.nf-caldr-month');
    let endMonth = state.template.querySelector(`.nf-caldr-day[data-date="${getCurrentDate(state, 1).endOf('month').format(DATE_FORMAT)}"]`)?.closest('.nf-caldr-month');
    if (startMonth === endMonth) {
        endMonth = null;
    }

    const setAvailabilityForDayWithData = setAvailabilityForDay(availability);
    if (startMonth instanceof HTMLElement) {
        startMonth.querySelectorAll('.nf-caldr-day').forEach(setAvailabilityForDayWithData);
    }

    if (endMonth instanceof HTMLElement) {
        endMonth.querySelectorAll('.nf-caldr-day').forEach(setAvailabilityForDayWithData);
    }

    paintDays(state);
    return true;
}

