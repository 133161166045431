// @ts-check

import dayjs from 'dayjs';
import {
    insertCalendarTemplate,
    setNightsSelectionDetails,
    setActiveYear,
    setActiveMonth,
    moveCalendar,
} from './ui'
import { paintDays } from './drawing'
import { isMobile } from './utils'
import { setAvailability } from './nfhotel'
import './types';

/**
 * Zwraca ilosc dni w miesiacu
 * @param {import('dayjs').Dayjs} date 
 * @param {number} offset 
 * @returns {number}
 */
export function getDaysInMonth(date, offset = 0) {
    return date.add(offset, 'month').daysInMonth();
}

/**
 * Sprawdza czy przekazany anchor graniczy z maksymalną datą
 * Dla kalendarzy z parametrem single ustawionym na true, zawsze zwraca false (TODO: dlaczego?)
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} targetAnchor
 * @return {boolean}
 */
export function isAnchorAtMaxDate(state, targetAnchor) {
    const { single, maxDate } = state;

    if (single) return false;

    return maxDate.isSame(targetAnchor, 'month')
}

/**
 * Zwraca pierwszy dzien miesiaca pokazywanej daty. Offset do pobrania nastepnego lub poprzedniego miesiaca
 * @param {CalState} state 
 * @param {number} localOffset 
 * @returns {import('dayjs').Dayjs}
 */
export function getCurrentDate(state, localOffset = 0) {
    return state.anchorDate.add(state.offset + localOffset, 'month').startOf('month');
}

/**
 * Czy poprzeni miesiac jest juz poza limitami
 * @param {CalState} state 
 * @returns {boolean}
 */
export function checkPrevMonthLimit(state) {
    return getCurrentDate(state).isSameOrBefore(state.minDate, 'month');
}

/**
 * Czy nastepny miesiac jest juz poza limitami
 * @param {CalState} state 
 * @returns {boolean}
 */
export function checkNextMonthLimit(state) {
    // minus 1 miesiace lub 2 miesiace bo tak. (a w szczegolach, z jakiegos powodu... nie chce mi sie szukac,
    // zawsze jest jeden miesiac za duzo, drugi jest z tego powodu ze na desktopie pokazywane sa 2 kalendarze wiec chce
    // sie zatrzymac przez nastepnym, nie tym wybranym)
    // UPDATE: juz wiedzialem dlaczego tak sie dzieje, to cos prostego ale zapomnialem, jezeli chcesz wiedziec o co chodzi, wyczekuj update 2
    return getCurrentDate(state).isSameOrAfter(state.maxDate.subtract(state.single ? 1 : 2, 'month'), 'day');
}

/**
 * Wlacza lub wylacza przyciski nastepnego lub poprzedniego miesiaca jezeli jest to ostatni miesiac przed limitami
 * @param {CalState} state 
 */
export function checkMonthLimits(state) {
    if (state.headless) return

    const calendarPreviousMonthButtonElement = /** @type {HTMLElement | null} */ (state.template.querySelector('.nf-caldr-prev-month-btn'));
    if (checkPrevMonthLimit(state) && calendarPreviousMonthButtonElement) {
    //TODO
       // calendarPreviousMonthButtonElement.style.display = 'none'
    } else if (calendarPreviousMonthButtonElement) {
        calendarPreviousMonthButtonElement.style.display = 'flex'
    }
    const calendarNextMonthButtonElement = /** @type {HTMLElement | null} */ (state.template.querySelector('.nf-caldr-next-month-btn'));
    if (checkNextMonthLimit(state) && calendarNextMonthButtonElement) {
        calendarNextMonthButtonElement.style.display = 'none'
    } else if (calendarNextMonthButtonElement) {
        calendarNextMonthButtonElement.style.display = 'flex'
    }
}

/**
 * @param {CalState} state
 * @param {(CustomEvent|string|number|Date)} eventOrStart Event z jedna lub oboma datami w
 * array jako payload, moze byc w jakimkolwiek formacie ktory akcjeptuje new Date(). jezeli
 * jest tylko jedna data, koniec ustawia na start, czyli 1 dzien zaznaczony
 * @param {(string|number|Date)} [end] Data koncowa
 */
export function injectDates(state, eventOrStart, end = 0) {
    const {key, rangeSelection, anchorDate, single, maxDate, template} = state; // + offset

    let startTime = null;
    let endTime = null;
    console.log('INJECT DATES');

    if (eventOrStart instanceof Event) {
        // ten event nie jest dla tego kalendarza
        if ((eventOrStart.detail.key && eventOrStart.detail.key !== key) || (!eventOrStart.detail.key && 1001 !== key)) return;

        if (!eventOrStart.detail.start) return 0;
        startTime = new Date(eventOrStart.detail.start);
        startTime.setHours(0, 0, 0, 0);
        startTime = dayjs(startTime);

        // jezeli jest tylko jedna data, ustaw koniec jako start (1 dzien zaznaczony)
        if (!eventOrStart.detail.end || !rangeSelection) {
            endTime = startTime.clone();
        } else {
            endTime = new Date(eventOrStart.detail.end);
            endTime.setHours(0, 0, 0, 0);
            endTime = dayjs(endTime);
        }
    } else {
        if (!eventOrStart) return 0;
        startTime = new Date(eventOrStart)
        startTime.setHours(0, 0, 0, 0)
        startTime = dayjs(startTime);

        // jezeli jest tylko jedna data, ustaw koniec jako start
        if (end === 0 || !rangeSelection) {
            endTime = startTime.clone();
        } else {
            endTime = new Date(end);
            endTime.setHours(0, 0, 0, 0);
            endTime = dayjs(endTime);
        }
    }

    const [newStart, newEnd, newAnchor] = getDatesInBoundaries(state, startTime, endTime);
    if (newStart) {
        state.startDate = newStart
    }
    state.endDate = newEnd
    if (!state.endDate) {
        state.endDate = state.startDate.clone();
    }
    if (newAnchor) {
        state.anchorDate = newAnchor
    }

    // ustaw nowy anchor date
    if (!single && anchorDate.add(1, 'month').isSameOrAfter(maxDate, 'day')) {
        state.anchorDate = anchorDate.month(anchorDate.month()).date(0);
    }
    state.offset = 0

    // zamknij dziwne divy
    const calendarMonthsContainerElement = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-months-container'));
    const calendarYearsElement = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-years'));

    if (calendarMonthsContainerElement) {
        calendarMonthsContainerElement.style.display = 'none'
    }

    if (calendarYearsElement) {
        calendarYearsElement.style.display = 'none'
    }

    if (state.hasOwnProperty('clearMode')) {
        if (state.clearMode) {
            state.startDate = null;
            state.endDate = null;
        }
    }
    console.log('INJECT DATES');
        //TODO async
    // stworz nowy kalendarz
    insertCalendarTemplate(state)
    moveCalendar(state)
    setAvailability(state)
    paintDays(state)
    checkMonthLimits(state)

    // ustaw nowe aktywne klasy
    setActiveMonth(state)
    setActiveYear(state)

    // ustaw detale zaznaczenia dni
    setNightsSelectionDetails(state, state.startDate, state.endDate)

    if (eventOrStart instanceof Event) {
        if (eventOrStart.detail.updateSelection) {
            if (state.onSelect) {
                state.onSelect(getSelectedDates(state)?.map(date => date.toDate()) || []);
            } else if (state.onDateSelect) {
                state.onDateSelect(getSelectedDates(state)?.map(date => date.toDate()) || []);
            }
        }
    }

    return getSelectedDates(state)
}

/**
 * Przywraca wybrane dni sprzed rozpoczenia nowego wyboru
 * @param {CalState} state 
 */
export function revertDates(state) {
    // jezeli jest ustawiony start ale nie ma konca, znaczy (chyba) ze kalendarz
    // zostal wylaczony przez zakonczeniem zaznaczenia, wiec cofnij zmiany
    if (state.startDate && !state.endDate) {
        injectDates(state, state.oldStartDate.toDate(), state.oldEndDate.toDate());

        /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-container')).removeEventListener('mouseover', state.mouseover);
    }
}

/**
 * Zwraca daty z sessionStorage lub, w razie ich braku, dzien dzisiejszy i nastepny
 * @param {CalState} state 
 * @returns {import('dayjs').Dayjs[]}
 */
export function loadDates(state) {
    const { key, rangeSelection } = state

    const [dateFrom, dateTo] = [
        window.sessionStorage.getItem((key ? (key + '-') : '') + 'nf-date-from'),
        window.sessionStorage.getItem((key ? (key + '-') : '') + 'nf-date-to')
    ]
    if (!dateFrom || !dateTo) {
        const today = dayjs().hour(0).minute(0).second(0).millisecond(0);
        return [today, rangeSelection ? today.add(1) : today.clone()]
    } else {
        return [dayjs(dateFrom), rangeSelection ? dayjs(dateTo) : dayjs(dateFrom)]
    }
}

/**
 * Zwraca aktualnie widoczny miesiac, w przypadku dwoch, zwraca tylko ten pierwszy
 * @param {CalState} state 
 * @returns {import('dayjs').Dayjs}
 */
export function getDisplayedMonths(state) {
    if (isMobile(state)) {
        const calendarElement = /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-calendar'));
        const firstMonth = Array.from(state.template.querySelectorAll('.nf-caldr-next-month')).filter(month => {
            const rect = month.getBoundingClientRect();

            return (rect.top <= calendarElement.clientHeight) && ((rect.top + rect.height) >= 100);
        })[0] || state.template.querySelector('.nf-caldr-next-month');
        const firstDay = /** @type {HTMLElement} */ (firstMonth.querySelector('.nf-caldr-month')).firstElementChild;
        return dayjs(firstDay?.getAttribute('data-date'));
    }
    return getCurrentDate(state)
}

/**
 * Zwraca aktualnie wybrany daty. Jezeli data poczatkowa i koncowa jest
 * taka sama, zwraca liste z tylko jednym elementem, w przeciwnym razie, z dwoma
 * @param {CalState} state 
 * @returns {import('dayjs').Dayjs[] | null}
 */
export function getSelectedDates(state) {
    if (!state.startDate || !state.endDate) {
        return null;
    }
    return state.startDate.isSame(state.endDate, 'day') ? [state.startDate] : [state.startDate, state.endDate];
}

/**
 * Zwraca daty pomiedzy wybranymi limitami, jezeli koniec jest dalej niz maksymalna data
 * lub poczatek jest wczesniej niz minimalna data, zwraca zamiast nich limity. Jezeli
 * obie daty sa poza limitami, zwraca w obu przypadkach null
 * @param {CalState} state 
 * @param {import('dayjs').Dayjs} start 
 * @param {import('dayjs').Dayjs} end 
 * @returns {Array<import('dayjs').Dayjs|null>} lista trzech elementow, pierwszy to poczatek, drugi to koniec, trzeci to anchor
 */
export function getDatesInBoundaries(state, start, end) {
    const { minDate, maxDate, allowSameDate } = state;

    if (start.isAfter(maxDate, 'day')) {
        const tempAnchor = maxDate.date(0);
        const anchorDate = maxDate.subtract(1, 'month').date(Math.min(maxDate.date(), tempAnchor.date()));
        return [null, null, anchorDate];
    }
    if (end.isAfter(maxDate, 'day')) {
        let startDate = start.clone();
        let endDate = maxDate.clone();
        if (!allowSameDate && startDate.isSame(endDate, 'day')) {
            endDate = startDate.clone();
            startDate = startDate.subtract(1, 'day');
        }
        const anchorDate = startDate.clone();
        return [startDate, endDate, anchorDate];
    }

    if (end.isBefore(minDate, 'day')) {
        const anchorDate = minDate.clone();
        return [null, null, anchorDate];
    }
    if (start.isBefore(minDate, 'day')) {
        const startDate = minDate.clone();
        let endDate = end.clone();
        if (!allowSameDate && startDate.isSame(endDate, 'day')) {
            endDate = endDate.add(1, 'day');
        }
        const anchorDate = startDate.clone();
        return [startDate, endDate, anchorDate];
    }

    let anchorDate = start.clone();
    if (isAnchorAtMaxDate(state, anchorDate)) {
        anchorDate = anchorDate.date(0);
    }
    return [start, end, anchorDate];
}
