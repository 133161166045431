// @ts-check

export let defaultLanguage = () => 'en'
export let defaultLocale = () => ({
    en: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        button: 'Check availability',
        clearButton: 'Clear dates',
        freeLabel: 'Free',
        occupiedLabel: "Occupied",
        dayLabel: "night",
        daysLabel: "nights",
        manyDaysLabel: 'nights',
        number_of_nights: 'How many nights from',
        want_create_reservation: 'do you want to create a reservation',
        today: 'today',
        cancel_selection: 'Cancel selection',
        clear_dates: 'Clear dates',
    },
    de: {
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        days: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        button: 'Verfügbarkeit prüfen',
        clearButton: 'Daten löschen',
        freeLabel: "Frei",
        occupiedLabel: "Besetzt",
        dayLabel:  "Nacht",
        daysLabel: "Nächte",
        manyDaysLabel: 'Nächte',
        number_of_nights: 'Wie viele Nächte von',
        want_create_reservation: 'möchten Sie eine Reservierung erstellen',
        today: 'heute',
        cancel_selection: 'Auswahl abbrechen',
        clear_dates: 'Daten löschen',
    },
    pl: {
        months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        days: ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'],
        button: 'Sprawdź dostępność',
        clearButton: 'Wyczyść daty',
        freeLabel: "Wolne",
        occupiedLabel: "Zajęte",
        dayLabel: "noc",
        daysLabel: "noce",
        manyDaysLabel: "nocy",
        number_of_nights: 'Na ile nocy od',
        want_create_reservation: 'chcesz utworzyć rezerwację',
        today: 'dziś',
        cancel_selection: 'Anuluj wybór',
        clear_dates: 'Wyczyść daty',
    },
    es: {
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        days: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        button: 'Comprobar disponibilidad',
        clearButton: 'Borrar fechas',
        freeLabel: "Libre",
        occupiedLabel: "Ocupado",
        dayLabel: "noche",
        daysLabel: "noches",
        manyDaysLabel: "noches",
        number_of_nights: 'Por cuántas noches desde',
        want_create_reservation: 'quieres hacer una reserva',
        today: 'hoy',
        cancel_selection: 'Cancelar selección',
        clear_dates: 'Borrar fechas',
    },
    it: {
        months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        days: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'],
        button: 'Verifica disponibilità',
        clearButton: 'Cancella date',
        freeLabel: "Libero",
        occupiedLabel: "Occupato",
        dayLabel: "notte",
        daysLabel: "notti",
        manyDaysLabel: "notti",
        number_of_nights: 'Per quante notti da',
        want_create_reservation: 'vuoi creare una prenotazione',
        today: 'oggi',
        cancel_selection: 'Annulla selezione',
        clear_dates: 'Cancella date',
    },
    fr: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        days: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        button: 'Vérifier la disponibilité',
        clearButton: 'Effacer les dates',
        freeLabel: "Libre",
        occupiedLabel: "Occupé",
        dayLabel: "nuit",
        daysLabel: "nuits",
        manyDaysLabel: "nuits",
        number_of_nights: 'Pour combien de nuits à partir de',
        want_create_reservation: 'voulez-vous créer une réservation',
        today: 'aujourd\'hui',
        cancel_selection: 'Annuler la sélection',
        clear_dates: 'Effacer les dates',
    },
    cs: {
        months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
        days: ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'],
        button: 'Zkontrolujte dostupnost',
        clearButton: 'Vymazat data',
        freeLabel: 'Volno',
        occupiedLabel: 'Obsazeno',
        dayLabel: "noc",
        daysLabel: "noci",
        manyDaysLabel: 'nocí',
        number_of_nights: 'Kolik nocí od',
        want_create_reservation: 'chcete vytvořit rezervaci',
        today: 'dnes',
        cancel_selection: 'Zrušit výběr',
        clear_dates: 'Vymazat data',
    },
    ru: {
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        button: 'Проверить доступность',
        clearButton: 'Очистить даты',
        freeLabel: 'Свободно',
        occupiedLabel: 'Занято',
        dayLabel: "ночь",
        daysLabel: "ночи",
        manyDaysLabel: 'ночей',
        number_of_nights: 'На сколько ночей с',
        want_create_reservation: 'вы хотите забронировать',
        today: 'сегодня',
        cancel_selection: 'Отменить выбор',
        clear_dates: 'Очистить даты',
    }
})

export const DATE_FORMAT = 'YYYY-MM-DD';
export const NFHOTEL_DATE_FORMAT = 'YYYY-MM-DD';
