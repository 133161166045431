// @ts-check

import dayjs from 'dayjs';
import { createPopper } from '@popperjs/core/lib/popper-lite';
import popperArrowModifier from '@popperjs/core/lib/modifiers/arrow';
import { getCurrentDate } from './dates';
import {
    setNightsSelectionDetails,
    showYearsView,
    showMonthsView,
    selectNextDayElement,
    getMonthElementFromTime,
    setButtonDisabled,
    onCalendarClose,
} from './ui';
import {sendOnDateSelect, setSessionStorageDates, isMobile, setMobile} from './utils';
import { DATE_FORMAT } from './config';
import './types';

/**
 * Ustawia klasy na hover w dniach pomiedzy zaznaczeniem
 * @param {CalState} state 
 * @param {Event} event 
 */
export function setDatesBetween(state, event) {
    // jezeli klik nie byl na dniu, zwroc wczesnie
    const eventTarget = event.target?.parentElement;
    if (eventTarget && !eventTarget.classList.contains('nf-caldr-day')) {
        return;
    }

    if (!(eventTarget instanceof HTMLElement)) {
        return;
    }

    // start jest juz ustawiony w setDateRange, bo funkcja dziala tylko po pierwszym kliknieciu
    let startDate = state.startDate.clone();
    let endDate = dayjs(eventTarget.getAttribute('data-date'));

    if (!state.startDayEl) {
        const startDateElement = /** @type {HTMLElement} */ (state.template.querySelector(`.nf-caldr-day[data-date="${startDate.format(DATE_FORMAT)}"]`));
        state.startDayEl = startDateElement;
    }
    let startDay = state.startDayEl
    let endDay = eventTarget

    // jezeli koniec jest przed startem, zamien
    let invertedTime = false;
    if (startDate.isAfter(endDate, 'day')) {
        invertedTime = true;
        let tempStart = startDate
        startDate = endDate
        endDate = tempStart
        endDay = state.startDayEl
        startDay = eventTarget
    }

    // jezeli pomiedzy poczatkiem a koncem jest wylaczony dzien, zaznacz poprzedni przed pierwszym wylaczonym
    const dayElementsBetweenStartAndEnd = Array.from(state.template.querySelectorAll('.nf-caldr-day[data-date]')).filter(dayEl => {
        const dayElTime = dayjs(dayEl.getAttribute('data-date'));
        return dayElTime.isBetween(startDate, endDate, 'day', '[]');
    });
    const disabledDayBetweenStartAndEnd = dayElementsBetweenStartAndEnd.filter(dayEl => {
        return !!dayEl.getAttribute('disabled');
    });
    if (disabledDayBetweenStartAndEnd.length) {
        if (invertedTime) {
            const lastDisabledButton = disabledDayBetweenStartAndEnd[disabledDayBetweenStartAndEnd.length - 1];

            // raczej zawsze bedzie taki element
            startDay = /** @type {HTMLElement} */ (state.template.querySelector(`[data-date="${dayjs(lastDisabledButton.getAttribute('data-date')).add(1, 'day').format(DATE_FORMAT)}"]`));
            startDate = dayjs(startDay.getAttribute('data-date'));
        } else {
            const firstDisabledButton = disabledDayBetweenStartAndEnd[0];
    
            endDay = /** @type {HTMLElement} */ (state.template.querySelector(`[data-date="${dayjs(firstDisabledButton.getAttribute('data-date')).subtract(1, 'day').format(DATE_FORMAT)}"]`));
            endDate = dayjs(endDay.getAttribute('data-date'));
        }

        const dayTooltip = /** @type {HTMLElement | null} */ (state.template.querySelector('#nf-caldr-day-tooltip'));
        if (dayTooltip) {
            dayTooltip.style.display = 'block';
            state.dayTooltip = createPopper(eventTarget, dayTooltip, { modifiers: [popperArrowModifier] });
        }
    } else {
        if (state.dayTooltip) {
            state.dayTooltip.state.elements.popper.style.display = 'none';
            state.dayTooltip.destroy();
            state.dayTooltip = null;
        }
    }

    // jezeli nie mozna zaznaczac tego samego dnia, wybierz koniec na nastepny dzien
    if (!state.allowSameDate && startDate.isSame(endDate, 'day')) {
        const newEndDate = endDate.add(1, 'day');
        const newEndDayElement = state.template.querySelector(`.nf-caldr-day[data-date="${newEndDate.format(DATE_FORMAT)}"]`);
        if (newEndDayElement && !!newEndDayElement.getAttribute('disabled')) {
            startDate = startDate.subtract(1, 'day');
            startDay = /** @type {HTMLElement} */ (state.template.querySelector(`[data-date="${startDate.format(DATE_FORMAT)}"]`));
        } else {
            endDate = endDate.add(1, 'day');
            endDay = /** @type {HTMLElement} */ (state.template.querySelector(`[data-date="${endDate.format(DATE_FORMAT)}"]`));
        }
    }

    /**
     * @param {HTMLElement} day
     */
    function setDayClasses(day) {
        day.classList.remove('nf-caldr-day-end')
        if (dayjs(day.getAttribute('data-date')).isBetween(startDate, endDate, 'day')) {
            day.classList.add('nf-caldr-day-middle')
        } else {
            day.classList.remove('nf-caldr-day-middle')
        }
    }

    const firstMonthDate = getCurrentDate(state)
    const secondMonthDate = getCurrentDate(state, 1)

    const firstMonthEl = /** @type {NodeListOf<HTMLElement>} */ (
        /** @type {HTMLElement} */ (state.template.querySelector(`.nf-caldr-day[data-date="${firstMonthDate.format(DATE_FORMAT)}"]`)?.parentNode)
            .querySelectorAll('.nf-caldr-day'));
    const secondMonthEl = /** @type {NodeListOf<HTMLElement>} */ (
        /** @type {HTMLElement} */ (state.template.querySelector(`.nf-caldr-day[data-date="${secondMonthDate.format(DATE_FORMAT)}"]`)?.parentNode)
            .querySelectorAll('.nf-caldr-day'));
    firstMonthEl.forEach(setDayClasses);
    secondMonthEl.forEach(setDayClasses);

    state.template.querySelector('.nf-caldr-day-start')?.classList.remove('nf-caldr-day-start');
    startDay.classList.add('nf-caldr-day-start');
    endDay.classList.add('nf-caldr-day-end');

    // ustaw detale zaznaczenia (to juz ostatecznie)
    setNightsSelectionDetails(state, startDate, endDate);
}

/**
 * Ustawia poczatkowy dzien wyboru, ustawia event listenery na hover
 * @param {CalState} state 
 * @param {MouseEvent} event 
 */
function setFirstDateRange(state, event) {
    const eventTarget = /** @type {HTMLElement | null} */ (event.target);

    if (!(eventTarget instanceof HTMLElement)) {
        return;
    }

    if (!!eventTarget.getAttribute('disabled')) {
        return;
    }

    // jezeli obie daty sa juz ustawione, usun klasy z ich elementow
    if (state.startDate && state.endDate) {
        const startEl = state.template.querySelector('.nf-caldr-day.nf-caldr-day-start')
        const endEl = state.template.querySelector('.nf-caldr-day.nf-caldr-day-end')

        startEl && startEl.classList.remove('nf-caldr-day-start')
        endEl && endEl.classList.remove('nf-caldr-day-end')
    }

    if(state.startDate && state.endDate) {
        // zapisz stare daty zeby mozna bylo je przywrocic po zamkniecu kalendarze bez wybrania drugiej daty
        state.oldStartDate = state.startDate.clone();
        if (state.endDate) {
            state.oldEndDate = state.endDate.clone();
        }
    }


    // ustaw pierwsza date i usun druga (zeby wiedziec ze nastepne klikniecie bedzie juz drugie)
    state.startDate = dayjs(eventTarget.getAttribute('data-date'));
    state.endDate = null

    /**
     * @param {HTMLElement} day 
     */
    function setDayClasses(day) {
        state.maxRange && setButtonDisabled(state, /** @type {HTMLButtonElement} */ (day))
        day.classList.remove('nf-caldr-day-middle')
    }

    // usun dni pomiedzy zeby miec czysty kalendarz
    if (isMobile(state)) {
        /** @type {NodeListOf<HTMLElement>} */ (state.template.querySelectorAll('.nf-caldr-day')).forEach(setDayClasses);
    } else {
        let firstMonthEl = getMonthElementFromTime(state, getCurrentDate(state));
        let secondMonthEl = getMonthElementFromTime(state, getCurrentDate(state, 1));
        /** @type {NodeListOf<HTMLElement>} */ (firstMonthEl.querySelectorAll('.nf-caldr-day')).forEach(setDayClasses);
        /** @type {NodeListOf<HTMLElement>} */ (secondMonthEl.querySelectorAll('.nf-caldr-day')).forEach(setDayClasses);
    }
    paintDays(state);

    // ustaw detale o wyborze dni
    const metaEndDate = state.allowSameDate ? state.startDate : state.startDate.add(1, 'day');
    setNightsSelectionDetails(state, state.startDate, metaEndDate);

    // event do zaznaczania dni pomiedzy na mouseover
    if (!isMobile(state)) {
        state.mouseover = (e) => setDatesBetween(state, e);
        state.headless
            ? state.template.addEventListener('mouseover', state.mouseover)
            : /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-container')).addEventListener('mouseover', state.mouseover);
        state.mouseover(event);
    }

    state.template.querySelectorAll('.nf-caldr-below__clear-dates').forEach(button => {
        button.textContent = state.locale[state.currentLanguage] && state.locale[state.currentLanguage].cancel_selection || state.locale.en.cancel_selection;
    });
}

/**
 * Ustawia dzien koncowy wyboru, zdejmuje listenery na hover.
 * @param {CalState} state 
 * @param {MouseEvent} event 
 */
function setLastDateRange(state, event) {
    const eventTarget = /** @type {HTMLElement | null} */ (event.target);

    if (!(eventTarget instanceof HTMLElement)) {
        return;
    }

    if(eventTarget.classList.contains('nf-caldr-day--not-available-end')) {
        return;
    }

    // jezeli druga data jest mniejsza od pierwszej, zamien je miejscami
    let invertedTime = false;
    if (state.startDate && dayjs(eventTarget.getAttribute('data-date')).isBefore(state.startDate, 'day')) {
        invertedTime = true;
        state.endDate = state.startDate;
        state.startDate = dayjs(eventTarget.getAttribute('data-date'));
    } else {
        state.endDate = state.rangeSelection ? dayjs(eventTarget.getAttribute('data-date')) : state.startDate.clone();
    }

    // jezeli pomiedzy poczatkiem a koncem jest wylaczony dzien, zaznacz poprzedni przed pierwszym wylaczonym
    const dayElementsBetweenStartAndEnd = Array.from(state.template.querySelectorAll('.nf-caldr-day[data-date]')).filter(dayEl => {
        const dayElTime = dayjs(dayEl.getAttribute('data-date'));
        return dayElTime.isBetween(state.startDate, state.endDate, 'day', '[]');
    });

    //jeżeli pomiędzy początkiem a końcem jest dzień który jest availablity end zaznacz ostatni dzień.
    const dayElementsBetweenStartAndEndAvailable = Array.from(state.template.querySelectorAll('.nf-caldr-day[data-date]')).filter(dayEl => {
        const dayElTime = dayjs(dayEl.getAttribute('data-date'));

        // Sprawdzamy, czy dzień znajduje się pomiędzy startDate a endDate
        const isBetweenDates = dayElTime.isBetween(state.startDate, state.endDate, 'day', '[]');

        // Sprawdzamy, czy dzień ma klasę 'nf-caldr-day--not-available-end'
        return isBetweenDates && dayEl.classList.contains('nf-caldr-day--availability-end');
    });

    console.log(dayElementsBetweenStartAndEndAvailable);


    let disabledDayBetweenStartAndEnd = dayElementsBetweenStartAndEnd.filter(dayEl => {
        return !!dayEl.getAttribute('disabled');
    });
    if(dayElementsBetweenStartAndEndAvailable.length) {
        disabledDayBetweenStartAndEnd = [...disabledDayBetweenStartAndEnd,...dayElementsBetweenStartAndEndAvailable]
    }


    if (disabledDayBetweenStartAndEnd.length) {
        if (invertedTime) {
            let lastDisabledButton = disabledDayBetweenStartAndEnd[disabledDayBetweenStartAndEnd.length - 1];

            if (dayElementsBetweenStartAndEndAvailable.length) {
                lastDisabledButton = dayElementsBetweenStartAndEndAvailable[dayElementsBetweenStartAndEndAvailable.length - 1];
            }

            state.startDate = dayjs(lastDisabledButton.getAttribute('data-date')).add(1, 'day');
        } else {
            const firstDisabledButton = dayElementsBetweenStartAndEndAvailable.length
                ? disabledDayBetweenStartAndEnd[disabledDayBetweenStartAndEnd.length -1]
                : disabledDayBetweenStartAndEnd[0];
            if (dayElementsBetweenStartAndEndAvailable.length) {
                state.endDate = dayjs(firstDisabledButton.getAttribute('data-date'));
            } else {
                state.endDate = dayjs(firstDisabledButton.getAttribute('data-date')).subtract(1, 'day');
            }
        }
    }

    if (state.dayTooltip) {
        state.dayTooltip.state.elements.popper.style.display = 'none';
        state.dayTooltip.destroy();
        state.dayTooltip = null;
    }

    // jezeli nie mozna zaznaczac tego samego dnia, wybierz koniec na nastepny dzien
    if (!state.allowSameDate && state.startDate && state.startDate.isSame(state.endDate, 'day')) {
        const newEndDate = state.endDate.clone().add(1, 'day');
        const newEndDayElement = state.template.querySelector(`.nf-caldr-day[data-date="${newEndDate.format(DATE_FORMAT)}"]`);
        if (newEndDayElement && !!newEndDayElement.getAttribute('disabled')) {
            state.startDate = state.startDate.subtract(1, 'day');
        } else {
            state.endDate = state.endDate.add(1, 'day');
        }
    }

    // usun event do zaznaczania dni pomiedzy
    state.headless
        ? state.template.removeEventListener('mouseover', state.mouseover)
        : /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-container')).removeEventListener('mouseover', state.mouseover)
    state.startDayEl = state.template.querySelector('.nf-cadr-day.nf-caldr-day-start');

    paintDays(state)

    // callback z zaznaczonymi dniami
    sendOnDateSelect(state)

    // ustaw wybor dni na utrzymanie pomiedzy stronami
    setSessionStorageDates(state)

    if (state.hideMeta || !state.onSelect) {
        onCalendarClose(state)
    }
    
    state.template.querySelectorAll('.nf-caldr-below__clear-dates').forEach(button => {
        button.textContent = state.locale[state.currentLanguage] && state.locale[state.currentLanguage].clear_dates || state.locale.en.clear_dates;

    });
}

/**
 * Ostatecznie ustawia klasy dni przy wybranym zaznaczeniu startu i konca
 * @param {CalState} state 
 */
export function paintDays(state) {
    if (!state.startDate) return

    // znajdz dzien z ta data
    let startEl = /** @type {HTMLElement | null} */
    (state.template.querySelector(`.nf-caldr-day[data-date="${state.startDate.format(DATE_FORMAT)}"]`))

    // ostatecznie ustaw klasy zanznaczenia
    let firstMonthEl = /** @type {HTMLElement} */ (
        state.template.querySelector(`.nf-caldr-day[data-date="${getCurrentDate(state).format(DATE_FORMAT)}"]`)?.parentNode)
    let secondMonthEl = /** @type {HTMLElement} */ (
        state.template.querySelector(`.nf-caldr-day[data-date="${getCurrentDate(state, 1).format(DATE_FORMAT)}"]`)?.parentNode)

    if(!firstMonthEl || !secondMonthEl) {
        return;
    }
    /**
     * @param {HTMLElement} day 
     */
    function setDayClasses(day) {
        day.classList.remove('nf-caldr-day-start')
        day.classList.remove('nf-caldr-day-end')
        if (state.endDate && dayjs(day.getAttribute('data-date')).isBetween(state.startDate, state.endDate, 'day', '(]')) {
            day.classList.add('nf-caldr-day-middle')
        } else {
            day.classList.remove('nf-caldr-day-middle')
        }
        state.endDate && setButtonDisabled(state, /** @type {HTMLButtonElement} */ (day))
    }

    if (isMobile(state)) {
        /** @type {NodeListOf<HTMLElement>} */ (state.template.querySelectorAll('.nf-caldr-day')).forEach(setDayClasses)
    } else {
        /** @type {NodeListOf<HTMLElement>} */
        (firstMonthEl.querySelectorAll('.nf-caldr-day')).forEach(setDayClasses);
        /** @type {NodeListOf<HTMLElement>} */
        (secondMonthEl.querySelectorAll('.nf-caldr-day')).forEach(setDayClasses);
    }

    if (state.endDate) {
        // znow znajdz dzien z ta data, ale koncowy
        let endEl = state.template.querySelector(`.nf-caldr-day[data-date="${state.endDate.format(DATE_FORMAT)}"]`)

        // dodaj do ostatniego dnia klase koncowa
        endEl && endEl.classList.add('nf-caldr-day-end')
        endEl && endEl.classList.remove('nf-caldr-day-middle')
    } else if (startEl && firstMonthEl.querySelector('.nf-caldr-day-end') && secondMonthEl?.parentNode?.querySelector('.nf-caldr-day-end')) {
        if (state.allowSameDate) {
            startEl?.classList.add('nf-caldr-day-end');
        } else {
            const nextElement = selectNextDayElement(state, startEl);
            nextElement?.classList.add('nf-caldr-day-end');
        }
    }

    // dodaj do pierwszego dnia klase startowoa
    startEl && startEl.classList.add('nf-caldr-day-start')
    startEl && startEl.classList.remove('nf-caldr-day-middle')
}

/**
 * Ustawia wybrany dzien w pojedynczym wyborze, ustawia klasy dni,
 * wysyla powiadomienie i zapisuje daty w sessionStorage
 * @param {CalState} state 
 * @param {MouseEvent} event 
 */
function setOnlyDateRange(state, event) {
    const eventTarget = /** @type {HTMLElement | null} */ (event.target);

    if (!(eventTarget instanceof HTMLElement)) {
        return;
    }

    const startEl = state.template.querySelector('.nf-caldr-day.nf-caldr-day-start')
    const endEl = state.template.querySelector('.nf-caldr-day.nf-caldr-day-end')
    startEl && startEl.classList.remove('nf-caldr-day-start')
    endEl && endEl.classList.remove('nf-caldr-day-end')

    state.startDate = dayjs(eventTarget.getAttribute('data-date'));
    state.endDate = state.startDate.clone();

    paintDays(state)

    // callback z zaznaczonymi dniami
    sendOnDateSelect(state)

    // ustaw wybor dni na utrzymanie pomiedzy stronami
    setSessionStorageDates(state)

    if (state.hideMeta || !state.onSelect) {
        onCalendarClose(state)
    }
}

/**
 * Rozpoczyna lub konczy wybor dni
 * @param {CalState} state 
 * @param {MouseEvent} event 
 * @returns {boolean|void}
 * TODO now
 */
export function setDateRange(state, event) {
    let eventTarget = /** @type {HTMLElement | null} */ (event.target);

    if (!(eventTarget instanceof HTMLElement)) {
        return;
    }

    if (!state.headless && eventTarget.classList.contains('nf-caldr-year-name')) {
        return showYearsView(state)
    }

    if (!state.headless && eventTarget.classList.contains('nf-caldr-month-name')) {
        return showMonthsView(state)
    }

    const attr = event.target.getAttribute('data-date');
    const newTarget = state.template.querySelector(
        `.nf-caldr-day[data-date='${attr}']`
    );
    console.log(newTarget);

    if (!newTarget || !newTarget.classList.contains("nf-caldr-day")) {
        return;
    }

    console.log(event.target);

    if (!state.rangeSelection) {
        return setOnlyDateRange(state, event)
    }
    // jezeli obie daty sa rozne, przyjmuje sie pierwsze klikniecie
    if ((!state.startDate && !state.endDate) || (state.startDate && state.endDate)) {
        setFirstDateRange(state, event)
    } else {
        setLastDateRange(state, event)
    }
}
