// @ts-check
import {
    isAnchorAtMaxDate,
    getCurrentDate,
    checkPrevMonthLimit,
    checkNextMonthLimit,
    checkMonthLimits,
    revertDates, getSelectedDates,
} from './dates';
import {
    createDateRangeEl,
    createNightsCountEl,
    createCalendarTemplate,
    createMonthTemplate,
} from './elements';
import {paintDays} from './drawing';
import {isMobile} from './utils';
import {setAvailability} from './nfhotel';
import {DATE_FORMAT} from './config';
import {CALENDAR_GAP} from './styles';
import './types';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);

let WRAPPER_WIDTH = 0;

// Funkcja do obserwowania zmian szerokości elementu
function observeWidthChange(element, callback) {
    if (!element) {
        console.warn(`Element not found: ${element}`);
        return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
            if (entry.contentRect) {
                const width = entry.contentRect.width;
                callback(width);
            }
        }
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
}


function observeWhenAvailable(selector, callback) {
    const interval = setInterval(() => {
        if (selector) {
            clearInterval(interval); // Zatrzymaj sprawdzanie
            observeWidthChange(selector, callback); // Rozpocznij obserwację
        }
    }, 100);
}

/**
 * @param {CalState} state
 */
export const changeWidthOnSingleCalendar = (state,callback) => {
   const clearMode = state.hasOwnProperty('clearMode');
   const singleMode = state.hasOwnProperty('single');
   if (!clearMode || !singleMode) {
       return false;
   }
   if (state.single && state.clearMode) {
        const element = state.template.querySelector(`.nf-calendar[key="${state.key}"] .nf-caldr-calendar`)
        observeWhenAvailable(element,async() => {
            moveCalendar(state);
            await getWrapperWidth(state)
            await insertCalendarTemplate(state);

           if(callback) callback();
       })
   } else {
       return false;
   }
}

/**
 *
 * @param state
 * @returns {Promise<void>}
 */
const getWrapperWidth = (state)  => {
    return new Promise((resolve, reject) => {
        const maxAttempts = 20;
        let attempts = 0;

        const check = () => {
            attempts++;
            const element = state.template.querySelector(`.nf-calendar[key="${state.key}"] .nf-caldr-calendar`);
            if (element) {
                WRAPPER_WIDTH = element.clientWidth;
                resolve(element.clientWidth);
            } else if (attempts >= maxAttempts) {
                console.error('error calendar wrapper width.');
                reject(new Error('Could not find calendar element width.'));
            } else {
                requestAnimationFrame(check);
            }
        };

        requestAnimationFrame(check);
    });
};


/**
 * Przypisuje do glownego elementu poczatkowe pierwsze miesiace, kasuje wszystkie ktore juz byly.
 * @param {CalState} state
 */
export function insertCalendarTemplate(state) {
    const calendarTemplate = /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-calendar'));
      getWrapperWidth(state).then((out) => {
        const wrapperWidth = out;
          calendarTemplate.innerHTML = `
<div class="nf-caldr-next-month" data-key="${state.offset -1}" style="right:${state.single ? `${wrapperWidth}px` : `${50 + CALENDAR_GAP}%`};">
    ${createCalendarTemplate(state, getCurrentDate(state, -1))}
</div>
<div class="nf-caldr-next-month" data-key="${state.offset}" style="right:0;">
    ${createCalendarTemplate(state, getCurrentDate(state))}
</div>
<div class="nf-caldr-next-month" data-key="${state.offset +1}" style="right:${state.single ? `-${wrapperWidth}px` : `-${50 + CALENDAR_GAP}%`};">
    ${createCalendarTemplate(state, getCurrentDate(state, 1))}
</div>`
    })
}

/**
 * Ustawia opis wybranych nocy w footerze kalendarza
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} start
 * @param {import('dayjs').Dayjs} end
 */
export function setNightsSelectionDetails(state, start, end) {
    if (!state.hideMeta && !state.single && !state.headless) {
        const calendarRange = state.template.querySelector('.nf-caldr-meta-range');
        const calendarCount = state.template.querySelector('.nf-caldr-meta-count');
        if (calendarRange) {
            calendarRange.textContent = createDateRangeEl(state, start, end)
        }
        if (calendarCount) {
            calendarCount.textContent = createNightsCountEl(state, start, end)
        }
    }
}

/**
 * Ustawia aktualny widok na wybor lat
 * @param {CalState} state
 * @returns {boolean}
 */
export function showYearsView(state) {
    const {template} = state // + startDayEl

    const calendarYearsElement = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-years'));
    const calendarMonthsElement = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-months-container'));
    if (!calendarYearsElement || calendarYearsElement.style.display === 'flex') return false;

    const activeYear = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-years .nf-caldr-years-active'));

    calendarYearsElement.style.display = 'flex'
    if (calendarMonthsElement) {
        calendarMonthsElement.style.display = 'none'
    }

    setActiveYear(state)

    const startOnDate = state.startOnDate;
    if (startOnDate) {
        const showYear = Array.from(/** @type {NodeListOf<HTMLElement>} */ (template.querySelectorAll('.nf-caldr-years-button')))
            .filter((el) => parseInt(el.textContent || '') === startOnDate.year())[0]
        calendarYearsElement.scrollTo(0, showYear.offsetTop - 80)
    } else if (activeYear) {
        calendarYearsElement.scrollTo(0, activeYear.offsetTop - 80);
    }

    revertDates(state)
    state.startDayEl = null;
    try {
        state.template.dispatchEvent(new Event('nfCalendarChangedView'));
    } catch (err) {
        console.error('Could not send event!')
    }
    state.currentView = 'years'
    return true
}

/**
 * Ustawia aktualny rok w widoku wyboru lat
 * @param {CalState} state
 */
export function setActiveYear(state) {
    const activeYear = state.template.querySelector('.nf-caldr-years-active');
    if (activeYear) {
        activeYear.classList.remove('nf-caldr-years-active')
    }

    if (state.startDate) {
        const currentYearEls = Array.from(state.template.querySelectorAll('.nf-caldr-years .nf-caldr-years-button'))
            .filter(el => parseInt(el.textContent || '') === state.startDate.year())
        currentYearEls.length > 0 && currentYearEls[0].classList.add('nf-caldr-years-active')
    }
}

/**
 * Ustawia wybrany rok
 * @param {CalState} state
 * @param {Event} event
 */
export function setYearDate(state, event) {
    // jezeli klik nie byl na roku, zwroc wczesnie
    const eventTarget = /** @type {HTMLElement | null} */ (event.target);
    if (eventTarget && !eventTarget.classList.contains('nf-caldr-years-button')) {
        return;
    }

    const selectedYear = parseInt(eventTarget?.textContent || '')

    state.anchorDate = state.anchorDate.year(selectedYear)
    state.offset = 0

    setActiveYear(state)

    const calendarYearsElement = /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-years'));
    if (calendarYearsElement) {
        calendarYearsElement.style.display = 'none'
    }
    showMonthsView(state)
    try {
        state.template.dispatchEvent(new Event('nfCalendarChangedView'));
    } catch (err) {
        console.error('Could not send event!')
    }
}

/**
 * Ustawia aktualny widok na wybor miesiecy
 * @param {CalState} state
 * @returns {boolean}
 */
export function showMonthsView(state) {
    const {template} = state // + startDayEl

    let monthDateMinus1 = getCurrentDate(state, 12 * -1)
    let monthDatePlus1 = getCurrentDate(state, 12 * 1)
    let monthDatePlus2 = getCurrentDate(state, 12 * 2)
    let monthDatePlus3 = getCurrentDate(state, 12 * 3)

    if (state.startOnDate) {
        monthDateMinus1 = state.startOnDate.add(-1, 'year');
        monthDatePlus1 = state.startOnDate.add(1, 'year');
        monthDatePlus2 = state.startOnDate.add(2, 'year');
        monthDatePlus3 = state.startOnDate.add(3, 'year');
    }

    const calendarMonthsElement = /** @type {HTMLElement | null} */ (state.template.querySelector('.nf-caldr-months-container'));
    if (!calendarMonthsElement) {
        return false;
    }
    const calendarYearsElement = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-years'));
    if (isMobile(state)) {
        calendarMonthsElement.innerHTML = `
            ${getCurrentDate(state, 12 * -1).isAfter(state.minDate, 'day') ? createMonthTemplate(state, monthDateMinus1) : ''}
            ${createMonthTemplate(state, state.startOnDate || getCurrentDate(state))}
            ${getCurrentDate(state, 12 * 1).isBefore(state.maxDate, 'day') ? createMonthTemplate(state, monthDatePlus1) : ''}
            ${getCurrentDate(state, 12 * 2).isBefore(state.maxDate, 'day') ? createMonthTemplate(state, monthDatePlus2) : ''}
            ${getCurrentDate(state, 12 * 3).isBefore(state.maxDate, 'day') ? createMonthTemplate(state, monthDatePlus3) : ''}
        `
    } else {
        calendarMonthsElement.innerHTML = createMonthTemplate(state, state.startOnDate || getCurrentDate(state))
    }
    setActiveMonth(state)

    if (calendarMonthsElement.style.display === 'block') return false

    if (calendarYearsElement) {
        calendarYearsElement.style.display = 'none'
    }
    calendarMonthsElement.style.display = 'block'

    const activeMonth = /** @type {HTMLElement | null} */ (template.querySelector('.nf-caldr-months-container .nf-caldr-months-active'));
    if (activeMonth) {
        calendarMonthsElement.scrollTo(0, activeMonth ? /** @type {HTMLElement} */ (activeMonth.parentNode).offsetTop - 80 : 210)
    }

    state.currentView = 'months'
    revertDates(state)
    state.startDayEl = null; // element dnia start, zeby bylo wiadomo gdzie jest start bez szukania
    try {
        state.template.dispatchEvent(new Event('nfCalendarChangedView'));
    } catch (err) {
        console.error('Could not send event!')
    }
    return true
}

/**
 * Ustawia aktualny miesiac w widoku wyboru miesiecy
 * @param {CalState} state
 */
export function setActiveMonth(state) {
    const calendarActiveMonthElement = state.template.querySelector('.nf-caldr-months-active');
    calendarActiveMonthElement && calendarActiveMonthElement.classList.remove('nf-caldr-months-active')
    if (state.startDate) {
        const currentActiveMonth = Array.from(state.template.querySelectorAll('.nf-caldr-months')).filter(month => parseInt(month.getAttribute('data-year') || '') === state.startDate.year())[0]
        currentActiveMonth && Array.from(currentActiveMonth.querySelectorAll('.nf-caldr-months-button'))[state.startDate.month()].classList.add('nf-caldr-months-active');
    }
}

/**
 * Ustawia wybrany miesiac
 * @param {CalState} state
 * @param {Event} event
 */
export function setMonthDate(state, event) {
    // jezeli klik nie byl na miesiacu, zwroc wczesnie
    const eventTarget = /** @type {HTMLElement | null} */ (event.target);
    if (eventTarget && !eventTarget.classList.contains('nf-caldr-months-button')) {
        return
    }

    if (!(eventTarget instanceof HTMLElement)) {
        return;
    }

    const selectedMonth = parseInt(eventTarget.getAttribute('data-month') || '');
    if (isNaN(selectedMonth)) {
        throw new Error('Invalid month for set month date.')
    }
    const selectedYear = parseInt(/** @type {HTMLElement} */ (eventTarget.parentNode).getAttribute('data-year') || '')
    if (isNaN(selectedMonth)) {
        throw new Error('Invalid year for set month date.')
    }
    let newAnchor = state.anchorDate.month(selectedMonth).year(selectedYear);

    // clamp
    if (newAnchor.isBefore(state.minDate, 'day')) {
        newAnchor = state.minDate.clone();
    }
    if (newAnchor.isAfter(state.maxDate, 'day') || isAnchorAtMaxDate(state, newAnchor)) {
        newAnchor = state.maxDate.clone();
        if (isAnchorAtMaxDate(state, newAnchor)) {
            newAnchor = newAnchor.date(0);
        }
    }

    state.anchorDate = newAnchor.clone();
    state.offset = 0

    insertCalendarTemplate(state)
    moveCalendar(state)

    setAvailability(state)

    setActiveMonth(state)

    paintDays(state)
    checkMonthLimits(state)
    const calendarMonthsElement = /** @type {HTMLElement | null} */ (state.template.querySelector('.nf-caldr-months-container'));
    if (calendarMonthsElement) {
        calendarMonthsElement.style.display = 'none'
    }
    try {
        state.template.dispatchEvent(new Event('nfCalendarChangedView'));
    } catch (err) {
        console.error('Could not send event!')
    }

    state.currentView = 'days'
}

/**
 * Zwraca nastepny element dnia
 * @param {CalState} state
 * @param {HTMLElement} dayElement
 * @param {number} offset
 * @return {HTMLElement|null}
 */
export function selectNextDayElement(state, dayElement, offset = 1) {
    const dayElementDate = dayjs(dayElement.getAttribute('data-date'));
    return state.template.querySelector(`.nf-caldr-day[data-date="${dayElementDate.add(offset, 'day').format(DATE_FORMAT)}"]`);
}

/**
 *
 * @param {CalState} state
 * @param {import('dayjs').Dayjs} dayTime
 * @returns {HTMLElement}
 */
export function getMonthElementFromTime(state, dayTime) {
    return /** @type {HTMLElement} */ (state.template.querySelector(`.nf-caldr-day[data-date="${dayTime.format(DATE_FORMAT)}"]`)?.parentNode)
}

/**
 * @param {CalState} state
 * @param {HTMLElement} day
 * @returns {boolean}
 */
function isDateOutOfBoundries(state, day) {
    const dateAttribute = dayjs(day.getAttribute('data-date'));
    return !dateAttribute.isBetween(state.minDate, state.maxDate, 'day', '[]');
}

/**
 * @param {CalState} state
 * @param {HTMLElement} day
 * @returns {boolean}
 */
function isDateGreaterThanMaxRange(state, day) {
    if (!state.startDate) {
        console.warn("isDateGreaterThanMaxRange: startDate is missing in state.");
        return false;
    }

    const dateAttribute = dayjs(day.getAttribute('data-date'));
    if (!state.maxRange) {
        return false;
    }

    const startDatePlusRange = state.startDate.add(state.maxRange, 'day');
    return !state.endDate && dateAttribute.isSameOrAfter(startDatePlusRange, 'day');
}


/**
 * @param {CalState} state
 * @param {HTMLElement} day
 * @returns {boolean}
 */
function isDateBeforeStart(state, day) {
    const dateAttribute = dayjs(day.getAttribute('data-date'));
    return !!state.maxRange && !state.endDate && dateAttribute.isBefore(state.startDate, 'day');
}

/**
 * @param {CalState} state
 * @param {HTMLElement} day
 * @returns {boolean}
 */
function isDateNotAvailable(state, day) {
    if (!day.closest('.nf-caldr-month')?.getAttribute('data-availability-loaded')) {
        return false;
    }

    return !!state.nfhotel &&
        !!state.nfhotel.standardId &&
        !!state.nfhotel.instanceId &&
        !day.getAttribute('data-availability');
}

/**
 * @param {CalState} state
 * @param {HTMLElement} day
 * @returns {boolean}
 */
function isDateBetweenTwoDisabled(state, day) {
    if (state.allowSameDate) {
        return false;
    }

    const dateAttribute = dayjs(day.getAttribute('data-date'));
    const previousDateElement = /** @type {HTMLElement | null} */ (
        state.template.querySelector(`.nf-caldr-day[data-date="${dateAttribute.subtract(1, 'day').format(DATE_FORMAT)}"]`));
    const nextDateElement = /** @type {HTMLElement | null} */ (
        state.template.querySelector(`.nf-caldr-day[data-date="${dateAttribute.add(1, 'day').format(DATE_FORMAT)}"]`));
    if (previousDateElement && nextDateElement &&
        (isDateOutOfBoundries(state, previousDateElement) || isDateNotAvailable(state, previousDateElement)) &&
        (isDateOutOfBoundries(state, nextDateElement) || isDateNotAvailable(state, nextDateElement))
    ) {
        return true;
    }
    return false;
}

export const isIncludeClassAvailabilityEnd = (state,day) => {
        const previousDateElement = state.template.querySelector(`.nf-caldr-day[data-date="${dayjs(day.getAttribute('data-date'))
            .subtract(1, 'day').format(DATE_FORMAT)}"]`);
        if (previousDateElement) {
            if(previousDateElement.classList.contains('nf-caldr-day--availability-end')) {
                day.classList.add('nf-caldr-day--not-available-end')
            }
        }
 //   }
}

/**
 * Ustawia czy dzien powinien byc wylaczony czy nie
 * @param {CalState} state
 * @param {HTMLButtonElement} day
 */
export function setButtonDisabled(state, day) {
    if (isDateOutOfBoundries(state, day)) {
        day.classList.add('nf-caldr-day--boundaries');
        day.setAttribute('disabled', 'disabled')
    } else if (isDateGreaterThanMaxRange(state, day)) {
        day.setAttribute('disabled', 'disabled')
    } else if (isDateBeforeStart(state, day)) {
        day.setAttribute('disabled', 'disabled')
    } else if (isDateNotAvailable(state, day)) {
        day.classList.add('nf-caldr-day--not-available');
        day.setAttribute('disabled', 'disabled');
    } else {
        day.classList.remove('nf-caldr-day--not-available');
        day.removeAttribute('disabled')
    }

    if (isDateBetweenTwoDisabled(state, day)) {
        day.setAttribute('data-end', 'disabled');
    }

    isIncludeClassAvailabilityEnd(state, day);
}

export const setHiddenOtherCalendars = (state) => {
    if (state.hasOwnProperty('single') && state.hasOwnProperty('clearMode')) {
        if (state.single && state.clearMode) {
            state.template.querySelectorAll('.nf-caldr-next-month').forEach(element => {
                const key = element.getAttribute('data-key');
                if (key == state.offset) {
                    element.style.opacity = 1;
                } else {
                    element.style.opacity = 0;
                }
            })
        }
    }
}

/**
 * Ustawia transform przy przesunieciu miesiaca
 * @param {CalState} state
 */
export function moveCalendar(state) {
    let opt = null;
    let offset_element = 0;
    if (state.offset !== 0) {
        opt = state.template.querySelector(`.nf-caldr-next-month[data-key="${state.offset}"]`);
        offset_element = -1 * -parseInt(opt.style.right, 10);
    } else {
        offset_element = 0;
    }
    setHiddenOtherCalendars(state);

    const element = state.template.querySelector('.nf-caldr-calendar');
    if (element) {
        /** @type {HTMLElement} */
        (state.template.querySelector('.nf-caldr-calendar')).style.transform =
            `translateX(${state.single ? offset_element : -1 * (state.offset + 1) * (50 + CALENDAR_GAP) + CALENDAR_GAP / 2}${state.single ? 'px' : '%'})`

    }
}

/**
 * Tworzy i wrzuca miesiac do kalendarza, rozpoczyna animacje, wlacza przyciski do przesuwania miesiecy,
 * wylacza przyciski dni i ustawia ich klasy
 * @param {CalState} state
 * @param {number} offset
 */
export function changeMonth(state, offset) {
    const newMonth = state.anchorDate.add(offset, 'month').date(1);
    getWrapperWidth(state).then(() => {
        const calendar = /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-calendar'));
        const oldOffset = state.offset;
        state.offset = newMonth.month() - state.anchorDate.month() + (newMonth.year() - state.anchorDate.year()) * 12;
        //TODO nowy miesiąc
        console.log(state.offset)
        const _offset = offset > 0 ? offset + 1 : offset;
        function createNewCalendar(date, transform) {
            const nextCalendar = createCalendarTemplate(state, date)
            const nextCalendarEl = document.createElement('div')
            nextCalendarEl.classList.add('nf-caldr-next-month')
            nextCalendarEl.setAttribute('data-key', _offset);
            nextCalendarEl.style.right = transform
            nextCalendarEl.innerHTML = nextCalendar
            return nextCalendarEl
        }
        let opt = 0;
        if (state.offset > 0) {
            opt = opt + 1;
        }
        const __offset = Math.abs((state.offset + opt) * (WRAPPER_WIDTH));
        // stworz nowe kalendarze, jezeli jest juz dzien z data nowego miesiaca, skip
        if (oldOffset > state.offset && !state.template.querySelector(`.nf-caldr-day[data-date="${newMonth.format(DATE_FORMAT)}"]`)) {
            const transformRight = state.single ? `${__offset}px` : Math.abs((state.offset) * (50 + CALENDAR_GAP)) + '%';
            const firstMonthInView = getMonthElementFromTime(state, getCurrentDate(state, 1)).parentNode;
            calendar.insertBefore(createNewCalendar(newMonth, transformRight), firstMonthInView);
        }
        if (!state.template.querySelector(`.nf-caldr-day[data-date="${newMonth.add(1, 'month').format(DATE_FORMAT)}"]`)) {
            const transformRight = state.single ? `-${__offset}px` : -((state.offset + 1) * (50 + CALENDAR_GAP)) + '%';
            calendar.appendChild(createNewCalendar(newMonth.clone().add(1, 'month'), transformRight));
        }

        setActiveYear(state);
        setActiveMonth(state);

        // przesun sie
        moveCalendar(state);

        setAvailability(state);

        // disabled nie ustawia sie na paintDays bo nie ma endDate wiec to skipuje
        if (!state.endDate && state.maxRange) {
            /** @type {NodeListOf<HTMLElement>} */ (getMonthElementFromTime(state, getCurrentDate(state, 1))
                .querySelectorAll('.nf-caldr-day'))
                .forEach(day => {
                    setButtonDisabled(state, /** @type {HTMLButtonElement} */(day))
                });
            /** @type {NodeListOf<HTMLElement>} */ (getMonthElementFromTime(state, getCurrentDate(state))
                .querySelectorAll('.nf-caldr-day'))
                .forEach(day => {
                    setButtonDisabled(state, /** @type {HTMLButtonElement} */(day))
                });
        }

        paintDays(state);
    })
}

/**
 * Ustawia anchor date na ten sam dzien rok w przod
 * @param {CalState} state
 * @returns {boolean}
 */
function incrementYear(state) {
    let newAnchor = state.anchorDate.clone();
    if (state.anchorDate.isSameOrAfter(state.maxDate, 'month')) return false;

    if (state.anchorDate.year() !== state.maxDate.year()) {
        newAnchor = newAnchor.add(1, 'year')
    }
    if (state.anchorDate.year() === state.maxDate.year()) {
        newAnchor = newAnchor.month(state.maxDate.month()).date(0);
    }
    state.anchorDate = newAnchor;

    /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-months-container')).innerHTML = createMonthTemplate(state, state.startOnDate || getCurrentDate(state));

    return true;
}

/**
 * Ustawia aktualny miesiac na nastepny
 * @param {CalState} state
 * @returns {boolean}
 */
function incrementMonth(state) {
    if (checkNextMonthLimit(state)) return false
    changeMonth(state, state.offset + 1)
    checkMonthLimits(state)
    return true
}

/**
 * Ustawia aktualny widok na wieksza jednostke, z dni na miesiace, z miesiecy na lata
 * @param {CalState} state
 * @returns {boolean}
 */
export function incrementView(state) {
    if (isMobile(state) === true) {
        return false
    }

    const currentView = getCurrentViewState(state)
    switch (currentView) {
        case 'months':
            return incrementYear(state)
        case 'days':
            return incrementMonth(state)
        default:
            return false
    }
}

/**
 * Ustawia anchor date na ten sam dzien rok wstecz
 * @param {CalState} state
 * @returns {boolean}
 */
function decrementYear(state) {
    let newAnchor = state.anchorDate.clone();
    if (state.anchorDate.isSameOrBefore(state.minDate, 'month'))
        return false;

    if (state.anchorDate.year() !== state.minDate.year()) {
        newAnchor = newAnchor.subtract(1, 'year');
    }
    if (state.anchorDate.year() === state.minDate.year()) {
        newAnchor = newAnchor.month(state.minDate.month());
    }
    state.anchorDate = newAnchor;

    /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-months-container')).innerHTML = createMonthTemplate(state, state.startOnDate || getCurrentDate(state));

    return true;
}

/**
 * Ustawia aktualny miesiac na poprzedni
 * @param {CalState} state
 * @returns {boolean}
 */
function decrementMonth(state) {
    if (checkPrevMonthLimit(state)) return false
    changeMonth(state, state.offset - 1)
    checkMonthLimits(state)
    return true
}

/**
 * Ustawia aktualny widok na mniejsza jednostke, z lat na miesiace, z miesiecy na dni
 * @param {CalState} state
 * @returns {boolean}
 */
export function decrementView(state) {
    if (isMobile(state) === true) {
        return false
    }

    const currentView = getCurrentViewState(state)
    switch (currentView) {
        case 'months':
            return decrementYear(state)
        case 'days':
            return decrementMonth(state)
        default:
            return false
    }
}

/**
 * Zwraca rodzaj aktywnego widoku w postaci stringa
 * @param {CalState} state
 * @return {"years"|"months"|"days"}
 */
export function getCurrentViewState(state) {
    return state.currentView
}

/**
 * Zamyka kalendarz
 * @param {CalState} state
 * @returns {boolean}
 */
export function onCalendarClose(state) {
    if (state.headless) return false;

    if (state.template.style.display === 'none') return false;

    if (state.dayTooltip) {
        state.dayTooltip.state.elements.popper.style.display = 'none';
        state.dayTooltip.destroy();
        state.dayTooltip = null;
    }

    state.template.style.display = 'none';
    document.body.style.overflow = '';

    /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-months-container')).style.display = 'none';

    revertDates(state);

    if (state.startDate){
        state.startDayEl = state.template.querySelector(`.nf-cadr-day[data-date="${state.startDate.format(DATE_FORMAT)}"]`);
    }

    state.template.querySelectorAll('.nf-caldr-below__clear-dates').forEach(button => {
        button.textContent = state.locale[state.currentLanguage] && state.locale[state.currentLanguage].clear_dates || state.locale.en.clear_dates;
    });

    return true;
}

const isButtonMode = (state) => {
    if(state.hasOwnProperty('buttonMode') && state.hasOwnProperty('simplify')) {
        return state.buttonMode
    } else {
        return false;
    }
}

/**
 * Otwiera kalendarz
 * @param {CalState} state
 * @returns {boolean}
 */
export function onCalendarOpen(state) {
    const {headless, template, startOnYears, startOnMonths, startDate} = state

    if (headless) return false

    if (template.style.display === 'flex') return false


    template.style.display = 'flex'
    document.body.style.overflow = 'hidden'

    if (startOnMonths) {
        showMonthsView(state)
    }

    if (startOnYears) {
        showYearsView(state)
    }

    if (!startOnMonths && !startOnYears && isMobile(state)) {
        const calendar = /** @type {HTMLElement} */ (template.querySelector('.nf-caldr-calendar'))
        const currentCalendar = template.querySelector(`.nf-caldr-next-month .nf-caldr-day[data-date="${startDate.format(DATE_FORMAT)}"]`)
        // sorry //a spierdalaj z tym sorry..
        calendar.scrollTo(0, /** @type {HTMLElement} */ (currentCalendar?.parentNode?.parentNode).offsetTop)
    }

    state.startOnDate = undefined
    // window.scrollTo(0,1)
    setTimeout(() => {
        paintDays(state);
    }, 50)

    if (state.hasOwnProperty('simplify')) {
        const isExists = document.querySelector(`.nf-calendar[key="${state.key}"] .nf-caldr-container #simplify-calendar`);
        if (isExists) return;
        const isSimplify = state.simplify;
        if (isSimplify) {
            const getDayOfWeek = (date) => dayjs(date).format('ddd');
            const currentLanguage = state.currentLanguage || 'en';
            dayjs.locale(currentLanguage);
            const dayLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].dayLabel) || state.locale.en.dayLabel;
            const daysLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].daysLabel) || state.locale.en.daysLabel;
            const manyDaysLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].manyDaysLabel) || state.locale.en.manyDaysLabel;
            const buttonLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].button) || state.locale.en.button;


            const numberOfNights = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].number_of_nights) || state.locale.en.number_of_nights;
            const wantCreateReservation = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].want_create_reservation) || state.locale.en.want_create_reservation;
            const tadayLabel = (state.locale[state.currentLanguage] && state.locale[state.currentLanguage].today) || state.locale.en.today;
            const isSelectedDay = (selected) => selected ? 'nf-caldr-simplify__item--selected' : '';

            const getDateTo = () => {
                const input = document.querySelector(`.nf-calendar[key="${state.key}"] #dayInput`);
                const dateTo = document.querySelector(`.nf-calendar[key="${state.key}"] #dateTo`);
                const dateToDay = document.querySelector(`.nf-calendar[key="${state.key}"] #dateToDay`);
                const dateLabel = document.querySelector(`.nf-calendar[key="${state.key}"] #dateLabel`);
                if (!input || !dateTo || !dateToDay || !dateLabel) {
                    console.error('Undefined DOM element.');
                    return;
                }
                ;
                dateTo.innerHTML = `<span style="font-weight:500">${dayjs().add(+input.value, 'day').format('DD MMM')}</span>`;
                dateToDay.innerHTML = `(${dayjs().add(+input.value, 'day').format('ddd')})`;

                let label;
                if (+input.value === 1) {
                    label = `${dayLabel}`;
                } else if (+input.value > 1 && +input.value < 5) {
                    label = `${daysLabel}`;
                } else {
                    label = `${manyDaysLabel}`;
                }
                dateLabel.innerHTML = `<span style="font-weight:500">${label}</span>`;
                state.endDate = dayjs().add(+input.value, 'day');
            }


            const days = [];
            for (let i = 1; i <= 6; i++) {
                let label;
                if (i === 1) {
                    label = `${i} ${dayLabel}`;
                } else if (i > 1 && i < 5) {
                    label = `${i} ${daysLabel}`;
                } else {
                    label = `${i} ${manyDaysLabel}`;
                }
                days.push({
                    id: i,
                    label: label,
                    value: dayjs().add(i, 'day'),
                    selected: i === 1
                });
            }
            state.startDate = dayjs();
            const dateToLabel = days[0].value.format('DD MMM YYYY');
            const dateDaysLabel = days[0].label;
            const block = document.createElement('div');
            block.id = 'simplify-calendar';
            if (!isButtonMode(state)) {
                block.classList.add('nf-caldr-simplify');
            }

            block.style.position = 'absolute';
            block.style.zIndex = 5000;
            block.style.width = '100%';
            block.style.height = '100%';
            block.style.background = 'white';
            block.style.left = '0';
            block.style.top = '0';
            if (!isButtonMode(state)) {
                block.innerHTML = `
                        <div style="margin: 28px">
                            <div style="display:flex;justify-content:center;font-weight: 500;margin-bottom: 50px;font-size: 24px">
                            <span style="margin-right: 4px">${numberOfNights}</span>
                            <span style="font-weight: 600;margin-right: 4px">${tadayLabel}</span>
                            <span>${wantCreateReservation}</span>
                            </div>
                            
                            <div style="display: grid;grid-template-columns: repeat(3, 1fr);grid-row-gap: 24px;grid-column-gap: 24px">
                             ${days.map(el => `
                                    <div id="${el.id}" class="nf-caldr-simplify__item ${isSelectedDay(el.selected)}">
                                        <div class="days" style="font-size: 24px">${el.label}</div>
                                        <div style="font-size: 14px;margin-top: 10px;">
                                            <span style="font-weight: 500">${dayjs().format('DD MMM')}</span><span class="day">(${getDayOfWeek(dayjs())})</span>
                                            <span>-</span>
                                            <span style="font-weight: 500">${el.value.format('DD MMM')}</span><span class="day">(${getDayOfWeek(el.value)})</span>
                                        </div>
                                    </div>
                                `).join('')}
                            </div>
                        </div>
                        `;
            } else {
                const height = 140;
                const buttonStyles = `
                        min-width: ${height}px;
                        max-width: ${height}px;
                        height:${height}px;
                        margin: 0 16px;
                        border-radius: 100%;
                        border: 1px solid ${state.style['accentColor']};
                        background: white;
                        font-size: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                `;
                const inputStyles = `
                        height: ${height}px;
                        width: 160px;
                        text-align: center !important;
                        font-size: 80px;
                        border: unset;
                        color: ${state.style['accentColor']};
                `;
                block.innerHTML = `
                    <div style="margin: 28px;position: relative">
                        <div style="display:flex;justify-content:center;font-weight: 500;margin-bottom: 50px;font-size: 28px">
                           <span style="margin-right: 4px">${numberOfNights}</span>
                           <span style="font-weight: 600;margin-right: 4px">${tadayLabel}</span>
                           <span>${wantCreateReservation}</span>
                        </div>
                        <div style="display: flex;align-items: center;justify-content: center;flex-direction: row;margin-top: 100px">
                         <button id="minusButton" style="${buttonStyles}">
                            <span style="padding-bottom: 6px">-</span>
                         </button>
                         <div style="position:relative">
                            <input id="dayInput" readonly type="number" min="1" max="20" style="${inputStyles}">
                            <div style="display: flex;justify-content: center;align-items: center;font-size: 30px;
                             bottom: -10px;
                             left: 50%;
                             transform: translateX(-50%);
                             position: absolute">
                            <span id="dateLabel"></span>
                        </div>
                         </div>
                        
                         <button id="plusButton" style="${buttonStyles}">
                            <span style="padding-bottom: 14px">+</span>
                         </button>
                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;margin-top:30px;font-size: 24px" 
                            <div>
                                 <span style="font-weight: 500">${dayjs().format('DD MMM')}</span><span class="day">(${getDayOfWeek(dayjs())})</span>
                                <span style="display: flex;align-items: center;justify-content: center;width: 30px">-</span>
                                 <span>
                                    <span id="dateTo"></span>
                                    <span class="day" id="dateToDay"</span> 
                                 </span>
                                </span>
                            </div>
                        </div>
                        <button
                        style="position: absolute;right: 28px;bottom: 28px;"
                         class="nf-lang-btn">${buttonLabel}</button>
                    </div>
                `;
            }
            // <span style="font-weight: 500">${el.value.format('DD MMM')}</span><span class="day">(${getDayOfWeek(el.value)})</span>-->

            const onSelectCallback = state.onSelect;
            document.querySelector(`.nf-calendar[key="${state.key}"] .nf-caldr-container`).appendChild(block);
            document.querySelectorAll('.nf-caldr-simplify__item').forEach(element =>
                element.addEventListener('click', ($event) => {
                    const target = $event.target.closest('.nf-caldr-simplify__item');
                    if (target) {
                        const id = target.id;
                        const _day = days.find(d => +d.id === +id);
                        if (_day) {
                            days.forEach(el => el.selected = false);
                            _day.selected = true;
                            state.endDate = _day.value;
                            document.querySelectorAll('.nf-caldr-simplify__item').forEach(el => {
                                el.classList.remove('nf-caldr-simplify__item--selected');
                            });
                            target.classList.toggle('nf-caldr-simplify__item--selected');
                            onSelectCallback(getSelectedDates(state)?.map(date => date.toDate()) || [], 0)
                            onCalendarClose(state)
                        }
                    }
                })
            )
            if (isButtonMode(state)) {
                const dayLimit = state.hasOwnProperty('buttonModeLimit') ? state.buttonModeLimit : 20;
                let __day = 1;
                //state on buttonMode
                const input = document.querySelector(`.nf-calendar[key="${state.key}"] #dayInput`);
                const buttonPlus = document.querySelector(`.nf-calendar[key="${state.key}"] #plusButton`);
                const buttonMinus = document.querySelector(`.nf-calendar[key="${state.key}"] #minusButton`)
                const submit = document.querySelector(`.nf-calendar[key="${state.key}"] .nf-lang-btn`);
                input.value = __day;
                setTimeout(() => {
                    getDateTo();
                    buttonMinus.classList.add('dbm-disabled');
                })

                submit.addEventListener('click', () => {
                    onSelectCallback(getSelectedDates(state)?.map(date => date.toDate()) || [], 0)
                    onCalendarClose(state);
                })

                buttonPlus.addEventListener('click', (event) => {
                    if (__day < dayLimit) {
                        __day++;
                        input.value = __day;
                        getDateTo();
                    } else {
                        buttonPlus.classList.add('dbm-disabled');
                    }
                    if (__day > 1) {
                        buttonMinus.classList.remove('dbm-disabled');
                    } else {
                        buttonMinus.classList.add('dbm-disabled');
                    }
                });

                buttonMinus.addEventListener('click', event => {

                    if (__day > 1) {
                        __day--;
                        input.value = __day;
                        getDateTo();
                    } else {
                        buttonMinus.classList.add('dbm-disabled')
                    }

                    if (__day > 1) {
                        buttonMinus.classList.remove('dbm-disabled');
                        buttonPlus.classList.remove('dbm-disabled');
                    } else {
                        buttonMinus.classList.add('dbm-disabled');
                    }
                });
            }
        }
    }

    return true
}

/**
 * Ustawia aktualne daty i wrzuca poprzednie lub nastepne miesiace w widoku mobilnym
 * @param {CalState} state
 */
export function handleMobileScroll(state) {
    // only for testing
    if (getCurrentViewState(state) === 'months') {
        const calendarMonthsContainer = /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-months-container'));
        const calendarMonthsContainerBox = calendarMonthsContainer.getBoundingClientRect();

        if (calendarMonthsContainer.scrollTop > calendarMonthsContainer.scrollHeight - calendarMonthsContainerBox.height - 20) {
            if (parseInt(calendarMonthsContainer.lastElementChild?.getAttribute('data-year') || '') >= state.maxDate.year()) {
                return;
            }
            const newMonth = document.createElement('template');
            const lastMonth = parseInt(calendarMonthsContainer.lastElementChild?.getAttribute('data-year') || '') - state.anchorDate.year();
            newMonth.innerHTML = createMonthTemplate(state, getCurrentDate(state, (lastMonth + 1) * 12));
            calendarMonthsContainer.appendChild(/** @type {HTMLElement} */ (newMonth.content.firstElementChild));
        }
        if (calendarMonthsContainer.scrollTop < 60) {
            if (parseInt(calendarMonthsContainer.firstElementChild?.getAttribute('data-year') || '') <= state.minDate.year()) {
                return;
            }

            const newMonth = document.createElement('template');
            const firstMonth = parseInt(calendarMonthsContainer.firstElementChild?.getAttribute('data-year') || '') - state.anchorDate.year();
            newMonth.innerHTML = createMonthTemplate(state, getCurrentDate(state, (firstMonth - 1) * 12));
            calendarMonthsContainer.insertBefore(/** @type {HTMLElement} */ (newMonth.content.firstElementChild), calendarMonthsContainer.firstElementChild);
        }
        return;
    }

    const cal = /** @type {HTMLElement} */ (state.template.querySelector('.nf-caldr-calendar'));
    const calBox = cal.getBoundingClientRect();
    if (cal.scrollTop > cal.scrollHeight - calBox.height - 280) {
        if (getCurrentDate(state).isSameOrAfter(state.maxDate.subtract(61, 'day').subtract(1, 'hour'))) {
            return;
        }
        changeMonth(state, state.offset + 1);
        checkMonthLimits(state);
    }
    if (cal.scrollTop < 280) {
        if (getCurrentDate(state, 1).isSameOrBefore(state.minDate, 'day')) {
            return;
        }
        changeMonth(state, state.offset - 1);
        checkMonthLimits(state);
        window.scrollTo(0, 281);
    }
}
