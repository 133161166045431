// @ts-check

import dayjs from "dayjs";
import dayJsIsBetween from "dayjs/plugin/isBetween";
import dayJsIsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayJsIsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {createStylesheet, createGlobalStylesheet, hasGlobalStylesheet} from "./styles";
import {
  injectDates,
  loadDates,
  getDisplayedMonths,
  getSelectedDates,
  getDatesInBoundaries,
} from "./dates";
import {createHeadlessTemplate, createTemplate} from "./elements";
import {
  insertCalendarTemplate,
  showYearsView,
  setYearDate,
  showMonthsView,
  setMonthDate,
  incrementView,
  decrementView,
  getCurrentViewState,
  onCalendarClose,
  onCalendarOpen,
  handleMobileScroll, changeWidthOnSingleCalendar, setHiddenOtherCalendars, changeMonth,
} from "./ui";
import { paintDays, setDateRange, setDatesBetween } from "./drawing";
import {mergeLocale, isMobile, setMobile, isInShadowRoot} from "./utils";
import { defaultLanguage, defaultLocale, DATE_FORMAT } from "./config";
import {availablitityHelperWhenRefreshView, removeAvailability, setAvailability} from "./nfhotel";
import "./types";

dayjs.extend(dayJsIsBetween);
dayjs.extend(dayJsIsSameOrBefore);
dayjs.extend(dayJsIsSameOrAfter);

let globalId = 0;

/**
 * Ustawia listenery wymagane
 * @param {CalState} state
 */
function setupCoreListeners(state) {
  const calendarYearsElement = state.template.querySelector(".nf-caldr-years");
  const calendarMonthsContainerElement = state.template.querySelector(
    ".nf-caldr-months-container",
  );
  const calendarContainerElement = /** @type {HTMLElement} */ (
    state.template.querySelector(".nf-caldr-container")
  );
  const calendarElement = /** @type {HTMLElement} */ (
    state.template.querySelector(".nf-caldr-calendar")
  );

  // do ustawiania roku
  if (calendarYearsElement) {
    calendarYearsElement.addEventListener("click", (e) =>
      setYearDate(state, e),
    );
  }
  if (calendarMonthsContainerElement) {
    // do ustawiania miesiecy
    calendarMonthsContainerElement.addEventListener("click", (e) =>
      setMonthDate(state, e),
    );
    // do przewijania miesiecy na mobile
    calendarMonthsContainerElement.addEventListener(
      "scroll",
      () => window.requestAnimationFrame(() => handleMobileScroll(state)),
      { passive: true },
    );
  }
  // do ustawiania dni
  state.headless
    ? state.template.addEventListener("click", (e) => setDateRange(state, e))
    : calendarContainerElement.addEventListener("click", (e) =>
        setDateRange(state, e),
      );

  // do przewijania miesiecy na mobile
  calendarElement.addEventListener(
    "scroll",
    () => window.requestAnimationFrame(() => handleMobileScroll(state)),
    { passive: true },
  );
}

function checkSelectedDates(state) {
  if (state.startDate && !state.endDate) {
    const nextDay = dayjs(state.startDate).add(1, "day").format("YYYY-MM-DD");
    const element = document.querySelector(
      `.nf-caldr-day[data-date="${nextDay}"]`,
    );

    if (element) {
      element.click();
    } else {
      state.endDate = dayjs(state.startDate).add(1, "day").toDate();
    }
    return;
  }

  if (!state.startDate && state.endDate) {
    const previousDay = dayjs(state.endDate)
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const element = document.querySelector(
      `.nf-caldr-day[data-date="${previousDay}"]`,
    );

    if (element) {
      element.click();
    } else {
      state.startDate = dayjs(state.endDate).subtract(1, "day").toDate();
    }
  }
}

/*
@param {CalState} state
 */
function setupSimplifyListeners(state) {
  const prevMonth = state.template.querySelector(
      ".nf-caldr-month-btn.nf-caldr-prev-month-btn",
  );
  const nextMonth = state.template.querySelector(
      ".nf-caldr-month-btn.nf-caldr-next-month-btn",
  );

  if (prevMonth) {
    prevMonth.addEventListener("click", () =>
        decrementView(state),
    );
  }
  if (nextMonth) {
    nextMonth.addEventListener("click", () =>
        incrementView(state),
    );
  }
}
/**
 * Opcjonalnie ustawia listenery do pelnego kalendarza, bez headless
 * @param {CalState} state
 */
function setupEventListeners(state) {
  const calendarBackdropElement =
    state.template.querySelector(".nf-caldr-backdrop");
  // do otwierania i zamykania
  if (calendarBackdropElement && !state.simplify) {
    calendarBackdropElement.addEventListener("click", () =>
      onCalendarClose(state),
    );
  }

  // do przycisku i dropdownu, nie testowane
  const onSelectCallback = state.onSelect;
  if (onSelectCallback && !state.hideMeta) {
    const buttonLocale = state.locale[state.currentLanguage].button;
    if (state.locale[state.currentLanguage] && Array.isArray(buttonLocale)) {
      buttonLocale.slice(1).forEach((_, idx) => {
        const calendarMetaCheckElement = state.template.querySelector(
          ".nf-caldr-meta-check-" + idx,
        );
        if (calendarMetaCheckElement) {
          calendarMetaCheckElement.addEventListener("click", () => {
            checkSelectedDates(state);
            onSelectCallback(
              getSelectedDates(state)?.map((date) => date.toDate()) || [],
              idx,
            );
            onCalendarClose(state);
          });
        }
      });
    } else {
      const calendarMetaCheckElement = state.template.querySelector(
        ".nf-caldr-meta-check",
      );
      if (calendarMetaCheckElement) {
        calendarMetaCheckElement.addEventListener("click", () => {
          checkSelectedDates(state);
          onSelectCallback(
            getSelectedDates(state)?.map((date) => date.toDate()) || [],
            0,
          );
          onCalendarClose(state);
        });
      }
    }
  }

  const closeMobileBlock = state.template.querySelector(
    ".nf-caldr-close-block",
  );

  if (closeMobileBlock) {
    closeMobileBlock.addEventListener("click", ($event) => {
      const mobileCloseButton = state.template.querySelector(
        ".nf-caldr-mobile-close",
      );
      if (mobileCloseButton) {
        mobileCloseButton.click();
        $event.stopPropagation();
        $event.preventDefault();
      }
    });
  }

  // do mobilnego wylacznika
  const calendarMobileCloseElement = state.template.querySelector(
    ".nf-caldr-mobile-close",
  );
  if (calendarMobileCloseElement) {
    calendarMobileCloseElement.addEventListener("click", () => {
      onCalendarClose(state);
    });
  }

  // do przewijania miesiecy
  const calendarPreviousMonthButtonElement = state.template.querySelector(
    ".nf-caldr-month-btn.nf-caldr-prev-month-btn",
  );
  const calendarNextMonthButtonElement = state.template.querySelector(
    ".nf-caldr-month-btn.nf-caldr-next-month-btn",
  );
  if (calendarPreviousMonthButtonElement) {
    calendarPreviousMonthButtonElement.addEventListener("click", () =>
      decrementView(state),
    );
  }
  if (calendarNextMonthButtonElement) {
    calendarNextMonthButtonElement.addEventListener("click", () =>
      incrementView(state),
    );
  }

  // do czyszczenia dat
  const calendarClearDatesButtonsElement = state.template.querySelectorAll(
    ".nf-caldr-below__clear-dates",
  );
  calendarClearDatesButtonsElement.forEach((button) =>
    button.addEventListener("click", () => {
      if (state.startDate && !state.endDate) {
        state.startDate = state.oldStartDate;
        state.endDate = state.oldEndDate;

        /** @type {HTMLElement} */ (
          state.template.querySelector(".nf-caldr-container")
        ).removeEventListener("mouseover", state.mouseover);

        if (state.dayTooltip) {
          state.dayTooltip.state.elements.popper.style.display = "none";
          state.dayTooltip.destroy();
          state.dayTooltip = null;
        }

        state.startDayEl = state.template.querySelector(
          `.nf-cadr-day[data-date="${state.startDate.format(DATE_FORMAT)}"]`,
        );

        paintDays(state);
        state.template
          .querySelectorAll(".nf-caldr-below__clear-dates")
          .forEach((button) => {
            button.textContent =
              (state.locale[state.currentLanguage] &&
                state.locale[state.currentLanguage].clear_dates) ||
              state.locale.en.clear_dates;
          });
        return;
      }

      state.template
        .querySelector(".nf-caldr-day.nf-caldr-day-start")
        ?.classList.remove("nf-caldr-day-start");
      state.template
        .querySelectorAll(".nf-caldr-day.nf-caldr-day-middle")
        .forEach((day) => day.classList.remove("nf-caldr-day-middle"));
      state.template
        .querySelector(".nf-caldr-day.nf-caldr-day-end")
        ?.classList.remove("nf-caldr-day-end");
    }),
  );

  window.addEventListener("resize", (e) =>
    window.innerWidth <= 768 ? setMobile(state, true) : setMobile(state, false),
  );
  if (window.innerWidth <= 768) {
    setMobile(state, true);
  }
}

/**
 * Tworzy nowy kalendarz i umieszcza go na koncu body
 *
 * @param {CalOptions} options
 */
function setupCalendar(options) {
  // ustaw defaultowe wartosci opcji
  console.log('NFHOTEL - build version', '24.1.25.14.38.00');
  if (!options)
    options = {
      single: false,
      minDate: dayjs()
        .year(1920)
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      maxDate: dayjs()
        .year(2050)
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      allowSameDate: false,
      locale: defaultLocale(),
    };
  if (!options.allowSameDate) options.allowSameDate = false;
  if (!options.single) options.single = false;
  if (!options.minDate) {
    options.minDate = dayjs()
      .year(1920)
      .month(0)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
  } else {
    options.minDate = dayjs(options.minDate);
  }
  if (!options.maxDate) {
    options.maxDate = dayjs()
      .year(2050)
      .month(0)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
  } else {
    options.maxDate = dayjs(options.maxDate);
  }

  const state = /** @type {CalState} */ (
    /** @type {unknown} */ ({
      template: null,
      startDate: null,
      startDayEl: null,
      endDate: null,
      oldStartDate: null,
      oldEndDate: null,
      mouseover: null,
      anchorDate: null,
      currentView: "days",
      offset: 0,
      ...options,
    })
  );

  // set language
  let isLocalData = localStorage.getItem("NfBookingEngine.languageCode");

  if (isLocalData) {
    state.currentLanguage = isLocalData;
  } else {
    if (options.hasOwnProperty('currentLanguage')) {
      state.currentLanguage = options.currentLanguage;
    }else {
      state.currentLanguage =
          window.navigator.language.slice(0, 2) || defaultLanguage();
    }
  }

  state.locale = mergeLocale(defaultLocale(), options.locale || {});

  if (!state.key) {
    state.key = ++globalId;
    if (state.disableCache) {
      state.key += 1000;
    }
  }

  // pobierz daty z sesji lub inicjalizuj do pierwszych dwoch dni
  if (state.disableCache) {
    state.anchorDate = dayjs();
    state.startDate = state.anchorDate
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    state.endDate = state.startDate
      .add(1, "day")
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
  } else {
    let [sessionStart, sessionEnd] = loadDates(state);
    if (!state.allowSameDate && sessionStart.isSame(sessionEnd, "day")) {
      sessionEnd = sessionEnd.add(1, "day");
    }
    const [start, end, anchor] = getDatesInBoundaries(
      state,
      sessionStart,
      sessionEnd,
    );
    if (start) {
      state.startDate = start;
    }
    state.endDate = end;
    if (anchor) {
      state.anchorDate = anchor;
    }
  }

  if (
    state.startOnDate &&
    !state.startOnDate.isBetween(state.minDate, state.maxDate, "day", "[]")
  ) {
    state.startOnDate = undefined;
    console.error("Option startOnDate has to be between minDate and maxDate.");
  } else if (state.startOnDate) {
    state.anchorDate = state.startOnDate;
  }

  state.currentView = "days";

  state.anchorDate = state.anchorDate
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  state.offset = 0;

  // create templates
  const template = state.headless
    ? createHeadlessTemplate(state)
    : createTemplate(state);
  state.template = template;

  // dodaj do documentu
  setupCoreListeners(state);
  const stylesheet = createStylesheet(state);
  if (state.container && isInShadowRoot(state.container)) {
    state.container.getRootNode().appendChild(stylesheet);
    state.container.getRootNode().appendChild(createGlobalStylesheet(state.container));
  } else {
    document.body.appendChild(stylesheet);
  }

  if (!state.headless) {
    setTimeout(() => {
      setupEventListeners(state);
    });
    document.body.appendChild(template);
  }

  changeWidthOnSingleCalendar(state, () => {
    setHiddenOtherCalendars(state);
  });

  if(state.headless && state.container){
    setupSimplifyListeners(state);
  }

  // sluchaj dat z zewnatrz
  template.addEventListener("nfCalendarDateInject", (event) =>
    injectDates(state, /** @type {CustomEvent} */ (event)),
  );


  // nie updatuj kalendarza jak sie zaczyna od roku lub miesiaca, bo kalendarz jeszcze nie istnieje
  if (!state.startOnYears || !state.startOnMonths) {
    insertCalendarTemplate(state);
    injectDates(state, state.startDate?.toDate(), state.endDate?.toDate());
  }

  try {
    window.dispatchEvent(new Event("nfCalendarInit"));
  } catch (err) {
    console.error("Could not send event!");
  }

  const checkMobile = document.querySelector("body")?.clientWidth;
  if (checkMobile) {
    if (checkMobile < 768) {
      setMobile(state, true);
    }
  }

  setAvailability(state);

  return {
    /**
     * Podnosi date o 1 w zaleznosci od obecnego widkou, na widoku dni, podnosi miesiac, na widoku misiecy, lata, w innym wypadku nic nie robi
     * @returns {boolean} jezeli widok zostal podniesiony zwraca prawde, w przeciwnym wypadku, jezeli nie udalo sie podniesc daty, zwraca false
     */
    incrementView: () => incrementView(state),
    /**
     * Obniza date o 1 w zaleznosci od obecnego widoku, na dniach, obniza miesiac, na widoku miesiecy, lata, w innym wypadku nic nie robi
     * @returns {boolean} jezeli widok zostal obnizony zwraca prawde, jezeli nie udalo sie obnizyc daty, zwraca false
     */
    decrementView: () => decrementView(state),
    /**
     * Otwiera kalendarz, nie robi nic w headless mode
     * @returns {boolean} true jezeli kalendarz zostal otwarty, false w przeciwnym wypadku
     */
    openCalendar: () => onCalendarOpen(state),
    /**
     * Zamyka kalendarz, nic nie robi w headless mode
     * @returns {boolean} true jezeli kalendarz zostal zamkniety, false w przeciwnym wypadku
     */
    closeCalendar: () => onCalendarClose(state),
    /**
     * Ustawia daty w kalendarzu
     * @param {Date} start Poczatkowa data
     * @param {Date} [end] Koncowa data
     * @return {Date[]} Tablica jedno elementowa w przypadku tylko startu lub dwu elementowa jezeli podane zostaly start i end
     */
    setDates: (start, end) =>
      (injectDates(state, start, end) || []).map((date) => date.toDate()),
    /**
     * Pokazuje widok miesiecy
     * @returns {boolean} true jezeli widok zostal pokazany, w przeciwym wypadku false
     */
    showMonths: () => showMonthsView(state),
    /**
     * Pokazuje widok lat
     * @returns {boolean} true jezeli widok zostal pokazany, w przeciwnym wypadku false
     */
    showYears: () => showYearsView(state),
    /**
     * Zwraca obecnnie pokazany miesac, w przypadku podwojnego miesiaca, zwraca pierwszy (z lewej)
     * @returns {Date} Data pierwszego dnia miesiaca
     */
    getDisplayedMonths: () => getDisplayedMonths(state).toDate(),
    /**
     * Zwraca zaznaczone daty
     * @returns {Date[]} Dwu elementowa tablica w przypadku zaznaczenia zasiegu lub
     * jedno elementowa tablica w przypadku zaznaczenia pojedynczego dnia
     */
    getSelectedDate: () =>
      (getSelectedDates(state) || []).map((date) => date.toDate()),
    /**
     * @returns {Date} Data anchor date, czyli poczatkowej daty przy stworzeniu kalendarza lub zmiany lat lub miesiecy
     * wokol ktorej kalendarz jest manipulowany przy pomocy offset
     */
    getAnchorDate: () => state.anchorDate.toDate(),
    /**
     * @type {HTMLDivElement} template Element root kalendarza do umieszczenia w headless lub do querowania na tym specyficznym kalendarzu.
     * Root element w przypadku headless mode to sam kalendarz, w przeciwnym caly kalendarz wlacznie z backdropem
     */
    template: state.template,
    /**
     * @returns {number} Zwraca offset wzgledem anchorDate
     */
    getOffset: () => state.offset,
    /**
     * Ustawia widok mobilny kalendarza
     * @param {boolean} mobile Aby wlaczyc widok mobilny, true, false zeby wylaczyc
     * @returns {boolean} Zwraca true jezeli widok zostal zmieniony, w przeciwym wypadku false.
     */
    setMobile: (mobile) => setMobile(state, mobile),
    /**
     * Zwraca rodzaj aktywnego widoku w postaci stringa
     * @return {"years"|"months"|"days"}
     */
    getCurrentViewState: () => getCurrentViewState(state),
    /**
     * Zwraca true jeżeli kalendarz jest w widoku pojedynczym
     * @return {boolean}
     */
    isSingle: () => state.single,
    /**
     * Zwraca true jeżeli kalendarz jest w trybie mobilnym
     * @return {boolean}
     */
    isMobile: () => isMobile(state),
    /**
     * Zwraca wartość właściwości o podanej nazwie,
     * zwraca undefined jeżeli właściwość nie istnieje
     * @param {String} prop
     * @return {undefined|*}
     */
    getStateProp: (prop) => {
      if (prop in state) return state[prop];
      return undefined;
    },
    /**
     * Zmienia właściwosć opcji o podanej nazwie
     * @param {String} prop
     * @param {*} val
     */
    setStateProp: (prop, val) => (state[prop] = val),
    /**
     * Odświeża widok kalendarza
     * @return {void}
     */
    refreshView: () => {
      availablitityHelperWhenRefreshView(state);
    },
    /**
     * Ustawia id standardu dla ktorego ma pokazac dostepnosc.
     * @param {number} standardId
     * @param {number|null} instanceId
     * @return {boolean}
     */
    setAvailability: (standardId, instanceId = null) => {
      if (instanceId === null && standardId === state.nfhotel?.standardId) {
        return true;
      }

      if (standardId === null) {
        return removeAvailability(state);
      }

      if (!state.nfhotel && !instanceId) {
        return false;
      } else if (!state.nfhotel) {
        state.nfhotel = /** @type {CalNFHotelOptions} */ ({});
      }

      if (instanceId) {
        state.nfhotel.instanceId = instanceId;
      }
      state.nfhotel.standardId = standardId;

      setAvailability(state);

      return true;
    },
    /**
     * Zwraca obecny obiekt stanu kalendarza
     * @return {CalState}
     */
    getState: () => state,
  };
}

const globalStylesheet = createGlobalStylesheet();
document.body.appendChild(globalStylesheet);

// dodaj do globalnego objektu
// @ts-ignore
if (!window.nfHotelBooking) {
  // @ts-ignore
  window.nfHotelBooking = {};
}
// @ts-ignore
window.nfHotelBooking.setupCalendar = setupCalendar;

try {
  window.dispatchEvent(new Event("nfCalendarReady"));
} catch (err) {
  console.error("Could not send event!");
}
